import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { ClientColumnConfig } from "@crispico/foundation-react/components/ColumnConfig/ClientColumnConfig";
import { getColumnConfigForClient } from "@crispico/foundation-react/components/ColumnConfig/ColumnConfigDropdown";
import { ColumnConfigDropdownSource } from "@crispico/foundation-react/components/ColumnConfig/dataStructures";
import { loadAndUpdateCustomQueryByName } from "@crispico/foundation-react/components/CustomQuery/CustomQueryDropdown";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { isIframe, XopsAppMeta } from "app";
import { Segment } from "semantic-ui-react";
import { OPTIONS_LOCAL_STORAGE_ITEM, TASK_SAS_DISPATCHING, TASK_SAS_LOST_OPERATION, TaskTablePage, TaskTablePageProps, TaskTablePageReducers, TaskTablePageState } from "./TaskEntityDescriptor";
import { ITableActionParamForRun } from "@crispico/foundation-react/entity_crud/EntityTableSimple";
import { IAction } from "@crispico/react-timeline-10000/types/components/ContextMenu/IAction";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { addAfterStartupRunnable } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import React, { ReactNode } from "react";
import { AppContainerContext } from "@crispico/foundation-react/AppContainerContext";
import { Utils } from "@crispico/foundation-react";
import { XopsAppContainerContextValue } from "XopsAppContainerContext";

type TaskEntityTablePageForSasProps = {
    defaultCQName: string
} & TaskTablePageProps

export let TaskEntityTablePageForSasRRC: React.ComponentType<Omit<Readonly<TaskEntityTablePageForSasProps> & Readonly<{ children?: ReactNode | undefined; }>, "s" | "r"> & { id: string; ref?: React.Ref<any>; }>;

/**
 * Following class needs to be created after startup, because we would use the RRC in TaskTablePage
 * and class also extends TaskTablePage => TaskTablePage is not initialized
 */
addAfterStartupRunnable(() => {
    class TaskEntityTablePageForSas extends TaskTablePage<TaskEntityTablePageForSasProps> {

        async componentDidMount() {
            let options = {
                showEquipmentResourcesWithoutMissions: this.props.defaultCQName === TASK_SAS_DISPATCHING ? true : false
            }

            window.localStorage.setItem(OPTIONS_LOCAL_STORAGE_ITEM, JSON.stringify(options));
            super.componentDidMount();

            let cq = await loadAndUpdateCustomQueryByName(this.props.defaultCQName, false);

            await this.columnConfigDropdownRef.current?.props.dispatchers.loadColumnConfigs("Task", ColumnConfigDropdownSource.TABLE);

            if (cq) {
                cq.screen = this.props.screen || "";
                this.customQueryBarRef.current?.props.dispatchers.updateCustomQuery(cq);
                if (cq.preferredColumnConfig) {
                    let cc: ClientColumnConfig = getColumnConfigForClient(cq.preferredColumnConfig);
                    cc.entityName = this.props.screen || cc.entityName;
                    this.columnConfigDropdownRef.current?.props.dispatchers.updateColumnConfig(cc);
                }
            }
        }

        protected async importDisplayForSasCssFile() {
            let displayForSas = (AppMetaTempGlobals.appMetaInstance as XopsAppMeta).getDisplayForSas();
            if ((isIframe() && displayForSas)) {
                // @ts-ignore
                await import("xops6-flex-mode.css");
            }
        }

        protected needToInitializeCC(): boolean {
            return false;
        }

        protected provideActionsForRow(actionParam: ITableActionParamForRun): IAction[] {
            return [];
        }

        protected preRenderButtons(params: {}): Array<OverrideableElement> {
            return [];
        }

        protected onDoubleClickItem(entity: any) {
        }

        /**
         * We need this method to render CC and CQ invisible in order to have their refs populated in order
         * to execute different operations in background
         */
        protected renderInvisibleElement(element: ReactNode) {
            return <div style={{ visibility: "hidden", height: "0px", width: "0px" }} >
                {element}
            </div>
        }

        protected renderCompactBar() {
            return <>
                <Segment className="less-margin-top-bottom gap5 flex-container-row flex-center flex-wrap EntityTablePage_bar" >
                    {this.renderRefreshButton()}
                    {this.renderInvisibleElement(this.renderColumnConfigDropdown())}
                    {/** We shouldn't allow changing of the layout from `Lost description` custom table because this
                 *   table use the same slice as the main table and the CC is changed also there.
                 */}
                    {this.props.defaultCQName === TASK_SAS_LOST_OPERATION ? this.renderCustomQueryBar((cq) => { }) : this.renderInvisibleElement(this.renderCustomQueryBar((cq) => { }))}
                    {this.props.s.options.showEquipmentResourcesWithoutMissions && this.renderEquipmentResourcesWithoutMissionsContent()}
                </Segment>
            </>;
        }

        render() {
            return <AppContainerContext.Consumer>
                {context => <>
                    <Utils.Observer value={context.initializationsForClient.currentOrganizationDropdownValue} didUpdate={() => this.refresh()} />
                    <Utils.Observer value={(context as XopsAppContainerContextValue).initializationsForClient.tempSettingsXops.displayForSas} didUpdate={() => this.importDisplayForSasCssFile()} />
                    {super.renderMain()}
                </>}
            </AppContainerContext.Consumer>;
        }
    }

    TaskEntityTablePageForSasRRC = ReduxReusableComponents.connectRRC(TaskTablePageState, TaskTablePageReducers, TaskEntityTablePageForSas);
});