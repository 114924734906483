import { EntityDescriptor, FieldDescriptor, Utils } from "@crispico/foundation-react";
import moment from "moment";
import { Label, Icon } from "semantic-ui-react";

export class QualificationEntityDescriptor extends EntityDescriptor {

    protected customize() {
        this.addFieldDescriptor({ name: "untilExpiration", clientOnly: true, filterable: false, sortable: false, isInDefaultColumnConfigForEditor: false }, new class extends FieldDescriptor {

            getAppearsInUi() {
                return true;
            }

            renderField(entity: any) {
                // also works w/ entity.endDate = null; duration = NaN
                const duration = moment.duration(moment(entity.endDate).diff(Utils.now()));
                let color: string;
                let icon: string;
                if (duration.milliseconds() <= 0) {
                    color = "red";
                    icon = "cancel";
                } else if (duration < moment.duration(1, "week")) {
                    color = "orange";
                    icon = "warning";
                } else if (duration < moment.duration(1, "month")) {
                    color = "yellow";
                    icon = "warning";
                } else {
                    color = "green";
                    icon = "check circle outline";
                }
                return <>
                    <Label horizontal color={color as any}>
                        <Icon name={icon as any} />
                        {duration.milliseconds() <= 0 ? _msg("Qualification.expired") : _msg("Qualification.valid")}
                    </Label>
                    {!isNaN(duration.milliseconds()) && (duration.milliseconds() <= 0 ? _msg("Qualification.expiredFor", duration.humanize()) : _msg("Qualification.untilExpiration", duration.humanize()))}
                </>
            }
        }());
    }

}