import { apolloClient, Utils } from "@crispico/foundation-react";
import { DecodedPositionInput } from "apollo-gen/globalTypes";
import { Checkbox } from "semantic-ui-react";
import { SEND_POSITION } from "./queries";
import _ from "lodash";
import { BluetoothDevicesPageSettings } from "./BluetoothDevicesPage";

const puckIdentifier = [2, 1, 6, 5, 22];

const bluetoothle = require("bluetoothle")

export interface AbstractBluetoothHelper {
    decodeAdvertisement: (advertisement: string) => { decodedAdvertisement: string[], checkIsPuck: boolean }
    scan: (addDevice: Function, changeValues: Function, scanInterval: number) => any,
    renderExtraOptions: (changeValues: Function, getValues: Function) => JSX.Element,
    getLabelForScanInterval: () => string;
    initializeSuccess?: (result: any, addDevice: Function, scanInterval: number, changeValues: Function) => void,
    startScan?: (addDevice: Function, scanInterval: number, changeValues: Function) => void,
    isPuck?: (advertisement: string) => boolean,
    sendPosition?: (deviceData: any, bluetoothDevicesPageSettings: BluetoothDevicesPageSettings | undefined) => void,
    checkIfAdvertisementWasChanged?: (previousAdvertisement: string[], advertisement: string[]) => boolean
}

export const MobileBluetoothHelper: AbstractBluetoothHelper = {
    // Decode advertisements based on https://elainnovation.com/wp-content/uploads/2022/06/BLE-Range-User-Guide-12F-EN-1.pdf,
    // Chapter 5, <<MOV>> format example
    decodeAdvertisement: (advertisement: string) => {
        let decodedAdvertisement = [advertisement];
        let checkIsPuck = false;
        if (MobileBluetoothHelper.isPuck && MobileBluetoothHelper.isPuck(advertisement)) {
            let bytes: any[] = bluetoothle.encodedStringToBytes(advertisement);
            let LSB = bytes[7];
            let MSB = bytes[8] << 8;
            let MOV_data = MSB | LSB;
            decodedAdvertisement = [
                _msg("BluetoothDevice.counter.label") + " " + (MOV_data >> 1),
                _msg("BluetoothDevice.level.label") + " " + (MOV_data & 1)
            ];
            checkIsPuck = true;
        }
        return { decodedAdvertisement, checkIsPuck };
    },

    scan: (addDevice: Function, changeValues: Function, scanInterval: number) => {
        changeValues({ scanStarted: true });
        document.addEventListener('deviceready', function () {
            new Promise(function (resolve) {
                bluetoothle.initialize(resolve, { request: true, statusReceiver: false });
            }).then((value) => MobileBluetoothHelper.initializeSuccess && MobileBluetoothHelper.initializeSuccess(value, addDevice, scanInterval, changeValues), (error) => console.log(error));
        });
    },

    renderExtraOptions: (changeValues: Function, getValues: Function) => {
        return <>
            <div className="flex-container">
                <label>{_msg("BluetoothDevice.puck.label")}</label>
                <Checkbox checked={(getValues().filterPucks)} toggle onChange={(event, data) => changeValues({ filterPucks: data.checked as boolean })} />
            </div>
            <div className="flex-container">
                <label>{_msg("BluetoothDevice.createPosition.label")}</label>
                <Checkbox checked={(getValues().createPositions)} onChange={(event, data) => changeValues({ createPositions: data.checked as boolean })} />
            </div>
        </>
    },

    initializeSuccess: (result: any, addDevice: Function, scanInterval: number, changeValues: Function) => {
        if (result.status === "enabled") {
            setTimeout(() => {
                MobileBluetoothHelper.startScan && MobileBluetoothHelper.startScan(addDevice, scanInterval, changeValues);
            }, scanInterval * 1000);
        }
    },

    startScan: (addDevice: Function, scanInterval: number, changeValues: Function) => {
        bluetoothle.startScan((value: any) => {
            if (value.status !== "scanStarted") {
                addDevice(value);
            }
        }, (error: any) => console.log(error), {
            "allowDuplicates": false,
            "callbackType": bluetoothle.CALLBACK_TYPE_ALL_MATCHES,
        });

        setTimeout(() => {
            bluetoothle.stopScan(() => { }, () => { });
            changeValues({ scanStarted: false });
            setTimeout(() => {
                MobileBluetoothHelper.startScan && MobileBluetoothHelper.startScan(addDevice, scanInterval, changeValues);
            }, scanInterval * 1000);
        }, scanInterval * 1000);
    },

    isPuck: (advertisement) => {
        let bytes = bluetoothle.encodedStringToBytes(advertisement);

        if (bluetoothle.bytesToEncodedString(puckIdentifier) ===
            bluetoothle.bytesToEncodedString(Array.from(bytes).splice(0, 5))) {
            return true;
        }

        return false;
    },

    sendPosition: (deviceData: any, bluetoothDevicesPageSettings: BluetoothDevicesPageSettings | undefined) => {
        let serverUrl: String = window.localStorage.getItem("XOPS_SERVER_URL") || "";
        let mobileDeviceId = (window.localStorage.getItem(serverUrl.split("\"").join("") + ".IDENTIFIER") || "").split("\"").join("");

        let deviceName: String = deviceData.name;
        if (bluetoothDevicesPageSettings && bluetoothDevicesPageSettings.plateNumberMaximumLength &&
            bluetoothDevicesPageSettings.plateNumberMaximumLength > 0 && bluetoothDevicesPageSettings.prefixes && bluetoothDevicesPageSettings.replicatedSequence) {
            let prefix = bluetoothDevicesPageSettings.prefixes.split(",").find(prefix => deviceName.startsWith(prefix));
            if (prefix) {
                deviceName = deviceName.replace(prefix, bluetoothDevicesPageSettings.replicatedSequence
                    .repeat(bluetoothDevicesPageSettings.plateNumberMaximumLength - deviceName.length + prefix.length));
            }
        }

        navigator.geolocation.getCurrentPosition(async (position) => {
            let decodedPosition: DecodedPositionInput = {
                plateNumber: mobileDeviceId,
                vehicleId: mobileDeviceId,
                vehicleName: mobileDeviceId,
                timestamp: Utils.now().toISOString(),
                dateReceived: Utils.now().toISOString(),
                gpsProvider: "X",
                fleetOwnerId: "XOPS",
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                accuracy: position.coords.accuracy,
                unknownProperties: { acc242: "|" + deviceName + "|" + deviceData.rssi }
            }

            await apolloClient.mutate({ mutation: SEND_POSITION, variables: { decodedPosition } })
        });
    },

    checkIfAdvertisementWasChanged: (previousAdvertisement: string[], advertisement: string[]) => {
        return _.differenceBy(previousAdvertisement, advertisement).length > 0;
    },

    getLabelForScanInterval: () => {
        return _msg("BluetoothDevicesPage.modal.content.mobile")
    }
}

export const WebBluetoothHelper: AbstractBluetoothHelper = {
    decodeAdvertisement: (advertisement: string) => { return { decodedAdvertisement: [], checkIsPuck: false } },

    scan: async (addDevice: Function, changeValues: Function, scanInterval: number) => {
        try {
            let scan = await navigator.bluetooth.requestLEScan({ acceptAllAdvertisements: true });

            changeValues({ scanStarted: true });

            navigator.bluetooth.addEventListener('advertisementreceived', event => {
                addDevice(event);
            });

            setTimeout(() => {
                changeValues({ scanStarted: false });
                scan.stop();
            }, scanInterval * 1000);
        }
        catch (error) {
            changeValues({ errorMessage: error as string });
        }
    },

    renderExtraOptions: (changeValues: Function, getValues: Function) => {
        return <></>;
    },

    sendPosition: (deviceData: any, bluetoothDevicesPageSettings: BluetoothDevicesPageSettings | undefined) => { },

    checkIfAdvertisementWasChanged: (previousAdvertisement: string[], advertisement: string[]) => {
        return false
    },

    getLabelForScanInterval: () => {
        return _msg("BluetoothDevicesPage.modal.content")
    }
}