import { EntityDescriptor, EntityTablePage, EntityTablePageState, EntityTablePageReducers, EntityTablePageProps } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

class FlightConnexionTablePage extends EntityTablePage<EntityTablePageProps> {
    addOneToManyModeFilters(filters: Filter[], oneToManyModeField: string, oneToManyModeEntityDescriptor: EntityDescriptor, oneToManyModeCachedId: any) {
        if (oneToManyModeField === "flight") {
            filters!.push(Filter.createComposed(FilterOperators.forComposedFilter.or,
                [Filter.create("incomingFlight", FilterOperators.forNumber.equals, oneToManyModeCachedId),
                Filter.create("outgoingFlight", FilterOperators.forNumber.equals, oneToManyModeCachedId)]));
        } else {
            super.addOneToManyModeFilters(filters, oneToManyModeField, oneToManyModeEntityDescriptor, oneToManyModeCachedId);
        }
    }
}

const FlightConnexionTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, FlightConnexionTablePage);

export class FlightConnexionEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "FlightConnexion",
            icon: "plane",
            defaultFilter: Filter.createForClient("incomingFlight.date", FilterOperators.forDate.today),
            defaultSort: { field: "incomingFlight.date", direction: "DESC" }
        });
    }
    
    protected customize() {
        this.addFieldDescriptor({ name: "incomingFlight", type: "Flight", additionalFieldEditorProps: { innerEntityDescriptor: entityDescriptors["Flight"] } });
        this.addFieldDescriptor({ name: "outgoingFlight", type: "Flight", additionalFieldEditorProps: { innerEntityDescriptor: entityDescriptors["Flight"] } });
        this.isInDefaultColumnConfig(true, "incomingFlight", "outgoingFlight", "incomingFlightDate", "outgoingFlightDate", "trieurIn", "trieurOut", "arrivedBaggages", "attendedBaggages", "confirmedBaggages", "totalBaggages", "pft", "inPftBaggages");
    }

    renderTable() {
        return <FlightConnexionTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
    }
}
