/****************************
********* CONSTANTS *********
****************************/

export const MissionStatus = {
    MISSION_STATUS_INVISIBLE: "invisible",
    MISSION_STATUS_DRAFT: "draft",
    MISSION_STATUS_NEW: "new",
    MISSION_STATUS_VIEWED: "viewed",
    MISSION_STATUS_STARTED: "started",
    MISSION_STATUS_FINISHED: "finished",
    MISSION_STATUS_TRANSFERED: "transfered",
    MISSION_STATUS_IN_POSITION: "inPosition",
    MISSION_STATUS_START_LOADING: "startLoading",
    MISSION_STATUS_STOP_LOADING: "stopLoading",
    MISSION_STATUS_START_PAUSE: "startPause",
    MISSION_STATUS_VALIDATED: "validated",
    MISSION_STATUS_PLANNED_NOT_DONE: "plannedNotDone",
    MISSION_STATUS_PLANNED_DONE_IN_OTHER_TASK: "plannedDoneInOtherFlightTask"
}

export const FLIGHT_IMPORTER_SARIA_J = "SariaJ";

export const TaskNames = {
    CONTAINER_TASK_NAME: "CTR",
    VRAC_TASK_NAME: "VRAC",
    CPB_TASK_NAME: "CPB",
    CAMION_TASK_NAME: "CAMION",
    DOLLY_CPC_TASK_NAME: "DOLLY_CPC",
    CAMION_DEBARQUE_TASK_NAME: "CAMION DEBARQUE",
    CREW_TASK_NAME: "CREW",
    PALETTE_TASK_NAME: "PALETTE",
}

export const Colors = {
    GREY: "#D1D1D1",
    DARK_GREEN: "#32751B",
    LIGHT_GREEN: "#29B543",
    LIGHTER_GREEN: "#49D562",
    PINK: "#DD47B3",
    LIGHT_BLUE: "#1593CE",
    LIGHTER_BLUE: "#92E1FA",
    DARK_BLUE: "#001EFF",
    WHITE: "#FFFFFF",
    BLACK: "#000010",
    ORANGE: "#D17A09",
    RED: "#E01313",
    LIGHT_RED: "#FF6761",
    DARK_GREY: "#424242",
    LIGHT_GREY: "#BDBDBD",
    BROWN: "#7E3E05",
}