import { addEntityDescriptor, apolloClient, EntityDescriptor, EntityTablePage, EntityTablePagePartialProps, EntityTablePageReducers, EntityTablePageState, FieldDescriptor, Optional, Utils } from "@crispico/foundation-react";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { DrivingEventForMap } from "apollo-gen/DrivingEventForMap";
import { loadDrivingEventsForMap, loadDrivingEventsForMapVariables } from "apollo-gen/loadDrivingEventsForMap";
import { HeatData, HEAT_TYPE, MapContainerLeafletRRC, MapContainerLeaflet, SelectedLayer, MARKER_TYPE, POLYGON_TYPE, PolygonData, Location, MarkerData, ID } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { LOAD_DRIVING_EVENTS_FOR_MAP } from "pages/DrivingEvent/queries";
import React from "react";
import { Dimmer, Loader, Button, Icon, Dropdown } from "semantic-ui-react";
import lodash from "lodash";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { MapGoToButton, RealTimeUtils } from "components/realTimeMap/RealTimeUtils";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { XopsAppContainerContextValue } from "XopsAppContainerContext";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { DrivingEvent_MeasurementUnitFieldDescriptor, DrivingEvent_TypeFieldDescriptor, DrivingEvent_ValueFieldDescriptor, DrivingEvent_VideoFieldDescriptor } from "./drivingEventFieldDescriptors";
import { COLUMN_DEFAULT_WIDTH } from "@crispico/foundation-react/components/ColumnConfig/dataStructures";
import { territoryEntityDescriptor } from "pages/Territory/territoryEntityDescriptor";
import { addressEntityDescriptor } from "AppEntityDescriptors";
import { TerritoryForMap } from "apollo-gen/TerritoryForMap";
import { AddressForMap } from "apollo-gen/AddressForMap";
import { Pair_Double_DoubleInput } from "apollo-gen/globalTypes";
import { LOAD_ADDRESSES_FOR_REAL_TIME_MAP, LOAD_TERRITORIES_FOR_REAL_TIME_MAP } from "components/realTimeMap/queries";
import { MarkerSettings } from "app";

const DEFAULT_COLUMNS = ["type", "value", "measurementUnit", "date", "equipmentResource", "humanResource", "longitude", "latitude", "video"];

class DrivingEventEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "DrivingEvent",
            miniFields: ["type", "equipmentResource.identifier", "humanResource.identifier"],
            icon: "car",
            defaultFilter: Filter.createForClient("date", FilterOperators.forDate.thisWeek),
            defaultSort: { field: "date", direction: "DESC" }
        });
    }

    getDefaultColumnConfig(forEditor?: boolean) {
        const cc = super.getDefaultColumnConfig(forEditor);
        cc.configObject!.columns = DEFAULT_COLUMNS.map(field => {
            return { name: field, width: COLUMN_DEFAULT_WIDTH }
        });
        return cc;
    }

    renderTable() {
        return <DrivingEventTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
    }
}

export const drivingEventEntityDescriptor = addEntityDescriptor(new DrivingEventEntityDescriptor()
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor(new DrivingEvent_TypeFieldDescriptor())
    .addFieldDescriptor(new DrivingEvent_ValueFieldDescriptor())
    .addFieldDescriptor(new DrivingEvent_MeasurementUnitFieldDescriptor())
    .addFieldDescriptor({ name: "equipmentResource", type: "EquipmentResource" })
    .addFieldDescriptor({ name: "humanResource", type: "HumanResource" })
    .addFieldDescriptor({
        name: "date", type: FieldType.date,
        additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat }),
        additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat })
    })
    .addFieldDescriptor({ name: "longitude", type: FieldType.double })
    .addFieldDescriptor({ name: "latitude", type: FieldType.double })
    .addFieldDescriptor(new DrivingEvent_VideoFieldDescriptor())
);

class DrivingEventTablePageState extends EntityTablePageState {
    drivingEvents: Optional<DrivingEventForMap[]> = undefined;
    showPointsOnMap: boolean = true;
    selectedEntityIndex: number | undefined = undefined;
    openEditor: boolean = false;
    showStaticEntities = { [territoryEntityDescriptor.name]: false, [addressEntityDescriptor.name]: false } as { [key: string]: boolean };

    territoriesForMap = [] as TerritoryForMap[];
    addressesForMap = [] as AddressForMap[];

}

class DrivingEventTablePageReducers<S extends DrivingEventTablePageState = DrivingEventTablePageState> extends EntityTablePageReducers<S> { }

type DrivingEventTablePageProps = EntityTablePagePartialProps & RRCProps<DrivingEventTablePageState, DrivingEventTablePageReducers>;

class DrivingEventTablePage<P extends DrivingEventTablePageProps = DrivingEventTablePageProps> extends EntityTablePage<P> {

    context!: XopsAppContainerContextValue;

    mapContainerRef = React.createRef<MapContainerLeaflet>();

    constructor(props: P) {
        super(props);       
        this.updateStaticEntities = this.updateStaticEntities.bind(this);   
        this.renderTooltipContent = this.renderTooltipContent.bind(this);
        this.renderMarkerIcon = this.renderMarkerIcon.bind(this);    
    }

    async loadDrivingEventsOnMap() {
        let filterFromCQ = this.getFilterForLoad();
        const events: Optional<DrivingEventForMap[]> = (await apolloClient.query<loadDrivingEventsForMap, loadDrivingEventsForMapVariables>({
            query: LOAD_DRIVING_EVENTS_FOR_MAP,
            variables: FindByFilterParams.create().filter(filterFromCQ)
        })).data.drivingEventService_findByFilter?.results;

        if (!events || events.length === 0) {
            this.props.r.setInReduxState({ drivingEvents: [] });
            return;
        }

        this.props.r.setInReduxState({ drivingEvents: events });
        this.addDrivingEventsOnMap();
    }

    addDrivingEventsOnMap() {
        let data: HeatData[] = [];
        Object.values(this.props.s.drivingEvents!).forEach((event: DrivingEventForMap) => {
            if (event.longitude && event.latitude) {
                data.push({ id: event.id, point: { longitude: event.longitude, latitude: event.latitude } });
            }
        });
        this.mapContainerRef.current?.addOrUpdateLayers(data, drivingEventEntityDescriptor.name);
    }

    componentDidUpdateInternal(prevProps?: P) {
        super.componentDidUpdateInternal(prevProps);

        // verify if [0, 0] -> default value; if not [0, 0], then it means the center was set by value from session storage, so we don't want to reset it
        if (this.mapContainerRef.current && lodash.isEqual(this.mapContainerRef.current!.props.s.center, [0, 0]) && this.context.initializationsForClient.mapSettings?.airport !== null) {
            RealTimeUtils.setAirportCoordinates(this.context.initializationsForClient.mapSettings?.airport!, this.mapContainerRef);
        }

        if (this.mapContainerRef && this.props.s.drivingEvents && prevProps?.s.showPointsOnMap !== this.props.s.showPointsOnMap) {
            this.mapContainerRef.current?.removeLayers(drivingEventEntityDescriptor.name);
            this.addDrivingEventsOnMap();
        }

        if (this.props.s.openEditor != prevProps?.s.openEditor) {
            const selectedId = this.mapContainerRef.current?.props.s.selectedLayer?.id;
            if (selectedId) {
                this.goToEditor(drivingEventEntityDescriptor.getEntityEditorUrl(selectedId));
            }
            this.props.r.setInReduxState({ openEditor: false });
        }
    }

    protected onCustomQueryFilterChanged() {
        this.props.r.setInReduxState({ drivingEvents: undefined });
        this.entityTableSimpleRef.current?.setSelected(undefined);
        this.mapContainerRef.current?.removeLayers(drivingEventEntityDescriptor.name);
        this.loadDrivingEventsOnMap();
        super.onCustomQueryFilterChanged();
    }

    protected onSelectItem(entityId: any): void {
        this.onSelectedEntityChanged(entityId, false);
    }

    protected getTableProps() {
        return { ...super.getTableProps(), scrollToRow: this.props.s.selectedEntityIndex };
    }

    protected onSelectedEntityChanged(entityId: number | undefined, fromMap: boolean) {
        const selectedLayer = this.mapContainerRef.current!.props.s.selectedLayer;
        switch (selectedLayer?.type) {
            case territoryEntityDescriptor.name:
                break;
            case addressEntityDescriptor.name:
                break;
            default: {
                if (entityId) {
                    if (fromMap) {
                        this.props.r.setInReduxState({ selectedEntityIndex: this.entityTableSimpleRef.current?.props.s.entities.findIndex(entity => entity.id === entityId) });
                        this.entityTableSimpleRef.current?.setSelected(entityId);
                    } else {
                        this.mapContainerRef.current!.props.r.setInReduxState({ selectedLayer: { id: entityId, type: drivingEventEntityDescriptor.name, additionalInfo: { flyToLayer: true } } });
                    }
                }
            }
        }
    }

    async loadTerritoriesForMap(currentBounds?: Pair_Double_DoubleInput[]) {
        const territories: Optional<TerritoryForMap[]> = (await apolloClient.query({
            query: LOAD_TERRITORIES_FOR_REAL_TIME_MAP,
            variables: { coordinates: currentBounds },
            context: { showSpinner: false }
        })).data.territoryService_findByRectangle?.results;

        if (territories && territories.length > 0) {
            await this.props.r.setInReduxState({ territoriesForMap: territories });
        }
    }

    addTerritoriesOnMap(mapContainer: Optional<MapContainerLeaflet>) {
        let polygons: PolygonData[] = [];
        this.props.s.territoriesForMap?.forEach((territory: TerritoryForMap) => {
            const points: Location[] = [];
            territory.coordinates?.forEach(p => {
                points.push({ longitude: p.a, latitude: p.b });
            });
            polygons.push({ id: territory.id, points: points, color: territory.color || undefined, text: territory.name || undefined });
        });
        mapContainer?.addOrUpdateLayers(polygons, territoryEntityDescriptor.name, true);
    }

    async loadAddressesOnMap(currentBounds?: Pair_Double_DoubleInput[]) {
        const addresses: Optional<AddressForMap[]> = (await apolloClient.query({
            query: LOAD_ADDRESSES_FOR_REAL_TIME_MAP,
            variables: { coordinates: currentBounds },
            context: { showSpinner: false }
        })).data.addressService_findByRectangle?.results;

        if (addresses && addresses.length > 0) {
            this.props.r.setInReduxState({ addressesForMap: addresses });
        }
    }

    addAddressesOnMap(mapContainer: Optional<MapContainerLeaflet>) {
        let data: MarkerData[] = [];
        this.props.s.addressesForMap?.forEach((a: AddressForMap) => {
            if (!Utils.isNullOrEmpty(a.longitude) && !Utils.isNullOrEmpty(a.latitude)) {
                data.push({ id: a.id, point: { longitude: a.longitude!, latitude: a.latitude! }, text: a.name || "" });
            }
        });
        mapContainer?.addOrUpdateLayers(data, addressEntityDescriptor.name, true);
    }

    protected async loadAndAddStaticEntitiesOnMap(entityDescriptorName: string) {
        if (entityDescriptorName == territoryEntityDescriptor.name) {
            await this.loadTerritoriesForMap(this.mapContainerRef.current?.getCurrentBounds());
            this.addTerritoriesOnMap(this.mapContainerRef.current);
        } else if (entityDescriptorName == addressEntityDescriptor.name) {
            await this.loadAddressesOnMap(this.mapContainerRef.current?.getCurrentBounds());
            this.addAddressesOnMap(this.mapContainerRef.current);
        }
    }

    protected showStaticEntitiesOnMap(entityDescriptorName: string, show: boolean) {
        if (show) {
            this.loadAndAddStaticEntitiesOnMap(entityDescriptorName);
        } else {
            this.mapContainerRef.current?.removeLayers(entityDescriptorName);
        }
        this.props.r.setInReduxState({ showStaticEntities: { ...this.props.s.showStaticEntities, [entityDescriptorName]: show } });
    }

    protected updateStaticEntities() {
        if (this.props.s.showStaticEntities[territoryEntityDescriptor.name]) {
            this.loadAndAddStaticEntitiesOnMap(territoryEntityDescriptor.name);
        }
        if (this.props.s.showStaticEntities[addressEntityDescriptor.name]) {
            this.loadAndAddStaticEntitiesOnMap(addressEntityDescriptor.name);
        }
    }

    renderTooltipContent(layerData: any, type: string, additionalInfo?: { pointId?: ID }): React.ReactElement {
        if (type == territoryEntityDescriptor.name) {
            return <><div>{layerData.text}</div></>;
        } else if (type == addressEntityDescriptor.name) {
            return <div className="flex-container">{layerData.text}</div>;
        }
        return <></>;
    }

    renderMarkerIcon(markerData: MarkerData, type: string, additionalStyles?: { selected?: boolean, hovered?: boolean }): React.ReactNode {
        if (type == addressEntityDescriptor.name) {
            return <><span className='fa fa-stack fa-lg'><i className={'fa fa-map-marker fa-stack-1x'} style={{ color: 'blue' }}></i></span></>;
        }
    }

    protected preRenderButtons(params: any): Array<OverrideableElement> {
        const { props } = this;
        return [
            ...super.preRenderButtons(params),
            {
                element:
                    <div key="selectAirport" className="MapContainerHeader_segment">
                        <Dimmer inverted active={this.props.s.drivingEvents === undefined}></Dimmer>
                        <Button color={this.props.s.showPointsOnMap ? "orange" : "teal"} onClick={() => this.props.r.setInReduxState({ showPointsOnMap: !this.props.s.showPointsOnMap })}><Icon name='point' />{_msg(this.props.s.showPointsOnMap ? "DrivingEventTable.hidePoints" : "DrivingEventTable.showPoints")}</Button>
                        <MapGoToButton options={RealTimeUtils.getMapGoToButtonProps(this.mapContainerRef)} />
                        <Dropdown trigger={<>{_msg("MapRealTime.showHide")}</>} icon='dropdown' button direction='left'>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    text={territoryEntityDescriptor.getLabel(true)}
                                    icon={props.s.showStaticEntities[territoryEntityDescriptor.name] ? "check square outline" : "square outline"}
                                    onClick={() => this.showStaticEntitiesOnMap(territoryEntityDescriptor.name, !props.s.showStaticEntities[territoryEntityDescriptor.name])}
                                />
                                <Dropdown.Item
                                    text={addressEntityDescriptor.getLabel(true)}
                                    icon={props.s.showStaticEntities[addressEntityDescriptor.name] ? "check square outline" : "square outline"}
                                    onClick={() => this.showStaticEntitiesOnMap(addressEntityDescriptor.name, !props.s.showStaticEntities[addressEntityDescriptor.name])}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
            }
        ];
    };

    renderTableSimple() {
        return (<>
            <Utils.Observer value={this.context.initializationsForClient.currentOrganization} didUpdate={() => {
                RealTimeUtils.selectAirportOnCurrentOrganizationToFilterByChange(this.mapContainerRef, this.context.initializationsForClient.currentOrganization);
            }} />
            <SplitPaneExt size="30%">
                {super.renderTableSimple()}
                <>
                    <Dimmer inverted active={this.props.s.drivingEvents === undefined}><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>
                    
                    <MapContainerLeafletRRC id={"mapContainerLeafletDrivingEventTable"} ref={this.mapContainerRef} mapId={"driving-event-map"} saveCenterZoomInStorage={true}
                        layers={{
                            [drivingEventEntityDescriptor.name]: { layerType: HEAT_TYPE, options: { hideTooltipOnHoveredLayer: true, showPoints: this.props.s.showPointsOnMap } },
                            [territoryEntityDescriptor.name]: { layerType: POLYGON_TYPE, options: { flyToSelectedMarker: false, hideStyleOnSelectedLayer: true, hideStyleOnHoveredLayer: true } },
                            [addressEntityDescriptor.name]: { layerType: MARKER_TYPE, options: { flyToSelectedMarker: false, hideStyleOnSelectedLayer: true, hideStyleOnHoveredLayer: true, useCluster: false } }
                        }}
                        pruneClusterMode={false}
                        renderTooltipContent={this.renderTooltipContent} renderMarkerIcon={this.renderMarkerIcon} 
                        onCoordinatesChanged={this.updateStaticEntities}
                        azureMapsAPIKey={this.context.initializationsForClient.mapSettings.azureMapsAPIKey}
                        bingAPIKey={this.context.initializationsForClient.mapSettings?.bingAPIKey}
                        onDoubleClickLayer={(prevValue: Optional<SelectedLayer>) => this.props.r.setInReduxState({ openEditor: true })}
                        onSelectLayer={() => this.onSelectedEntityChanged(this.mapContainerRef.current?.props.s.selectedLayer?.id as number, true)} />
                </>
            </SplitPaneExt>
        </>)
    }
}

const DrivingEventTablePageRRC = ReduxReusableComponents.connectRRC(DrivingEventTablePageState, DrivingEventTablePageReducers, DrivingEventTablePage);