import gql from "graphql-tag";


export const DRIVING_EVENT_FOR_MAP = gql`
    fragment DrivingEventForMap on DrivingEvent {
        id latitude longitude
    }   
`;

export const LOAD_DRIVING_EVENTS_FOR_MAP = gql`
    query loadDrivingEventsForMap($params: FindByFilterParamsInput) { 
        drivingEventService_findByFilter(params: $params) {
            results  { ...DrivingEventForMap } 
        }
    }
    ${DRIVING_EVENT_FOR_MAP}
`;

export const LOAD_VIDEOS_BY_DRIVING_EVENT = gql`
    query getVideosByDrivingEvent($drivingEventId: Long!) {
        smartwitnessService_videosByDrivingEvent(drivingEventId: $drivingEventId) {
            date thumbnailPath videoPath eventType cameraChannel entityId latitude longitude duration 
        }
    }
`;

export const DRIVING_EVENT_FOR_VIDEO_MODAL = gql`
    fragment DrivingEventForVideoModal on DrivingEvent {
        id latitude longitude type value date equipmentResource { id identifier plateNumber videoRecorderId } humanResource { id firstName lastName }
    }   
`;

export const LOAD_DRIVING_EVENT_FOR_VIDEO_MODAL = gql`
    query loadDrivingEventForVideoModal($id: Long!) { 
        drivingEventService_findById(id: $id) {
            ...DrivingEventForVideoModal 
        }
    }
    ${DRIVING_EVENT_FOR_VIDEO_MODAL}
`;