import { EntityTableSimple, EntityTableSimpleProps, EntityTableSimpleReducers, EntityTableSimpleState } from "@crispico/foundation-react";
import { ReactNode } from "react";
import { Button } from "semantic-ui-react";
import { default as _ } from "lodash";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Cell, Column } from "fixed-data-table-2";
import { QualificationType } from "./QualificationMultipleEditor";

type QualificationTypeCustomTableSimpleProps = {
    replaceQualificationType: (qualificationType: QualificationType) => void,
    openDatesModalInDefaultMode: (selectedQualificationType: QualificationType, enableQualificationType: boolean) => void
} & EntityTableSimpleProps

class QualificationTypeCustomTableSimple extends EntityTableSimple<QualificationTypeCustomTableSimpleProps> {

    protected getContextMenuColumn(): ReactNode {
        return <Column width={120} key="menu" cell={props => {
            const { rowIndex } = props;
            return <Cell key={rowIndex} className="QualificationTableSimple_menuButtons">
                <Button icon={this.getEntity(rowIndex).enabled ? "close" : "add"}
                    positive={!this.getEntity(rowIndex).enabled} negative={this.getEntity(rowIndex).enabled}
                    onClick={() => {
                        if (this.getEntity(rowIndex).enabled) {
                            let entity = _.cloneDeep(this.getEntity(rowIndex));
                            entity.enabled = false;
                            entity.startDate = undefined;
                            entity.endDate = undefined;
                            this.props.replaceQualificationType(entity);
                            return;
                        }
                        this.props.openDatesModalInDefaultMode(this.getEntity(rowIndex), true);
                    }} />
                <Button icon="edit" primary onClick={() => {
                    this.props.openDatesModalInDefaultMode(this.getEntity(rowIndex), false);
                }} />
            </Cell>
        }} />;
    }

    protected renderColumns() {
        let columns = super.renderColumns();
        columns.unshift(this.getContextMenuColumn());
        return columns;
    }

}

export const QualificationTypeCustomTableSimpleRRC = ReduxReusableComponents.connectRRC(EntityTableSimpleState, EntityTableSimpleReducers, QualificationTypeCustomTableSimple);