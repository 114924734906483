import gql from "graphql-tag";

export const APPLY_FOA_FOR_FLIGHTS = gql`
    mutation applyFoaForFlights($flightFilter: FilterInput, $foaFilter: FilterInput) {
        flightAndObjectActionService_apply(flightFilter: $flightFilter, foaFilter: $foaFilter)
    }
`;

export const APPLY_FOA_FOR_SINGLE_FLIGHT = gql`
    mutation applyFoaForSingleFlight($flightId: Long, $foaFilter: FilterInput) {
        flightAndObjectActionService_applyOnSingleFlight(flightId: $flightId, foaFilter: $foaFilter)
    }
`;

export const LOAD_REGISTRATIONS_PARAMETER = gql`
    query loadRegistrationsParameter($params: FindByFilterParamsInput) {
        parameterService_findByFilter(params: $params) {
            results { id name value }
        }
    }
`;

export const SAVE_REGISTRATIONS_PARAMETER = gql`
    mutation saveRegistrationsParameter($params: SaveParams_LongInput) { 
        parameterService_save(params: $params) { id name value }
    }
`;