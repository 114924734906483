import { ADD, EntityDescriptor, EntityEditorFormSimple, FieldDescriptor, apolloClient } from "@crispico/foundation-react";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { HumanResourceInput, QualificationTypeInput } from "apollo-gen/globalTypes";
import React from "react";
import { Button, Segment, Menu, ModalContent, MenuItem, Popup, Icon } from "semantic-ui-react";
import { DELETE_BY_FILTER_QUALIFICATIONS, LOAD_HUMAN_RESOURCE, LOAD_QUALIFICATION_TYPES, UPDATE_QUALIFICATIONS } from "./queries";
import { FindByStringParams } from "@crispico/foundation-react/entity_crud/FindByStringParams";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { RRCProps, Reducers, ReduxReusableComponents, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { COLUMN_DEFAULT_WIDTH } from "@crispico/foundation-react/components/ColumnConfig/dataStructures";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import moment from "moment";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { SaveFieldsParams } from "@crispico/foundation-react/entity_crud/SaveFieldsParams";
import { QualificationTypeCustomTableSimpleRRC } from "./QualificationTypeCustomTableSimple";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";

const OTHERS = "Others";
export const HUMAN_RESOURCE_ID = "humanResourceId";
export const QUALIFICATION_MULTIPLE_EDITOR_PATH = "/manageQualificationsPage";

enum EditorMode {
    DEFAULT = "DEFAULT",
    MULTIPLE = "MULTIPLE"
}

const qualificationTypeTableSimpleColumns = ["name", "planeType", "planeTypeGroup", "startDate", "endDate"].map(name => {
    return { name, width: COLUMN_DEFAULT_WIDTH };
});

const qualificationTypeEntityDescriptor = new EntityDescriptor({ name: "QualificationType" })
    .addFieldDescriptor({ name: "startDate", type: FieldType.date })
    .addFieldDescriptor({ name: "endDate", type: FieldType.date });

export type QualificationType = QualificationTypeInput & {
    enabled: boolean,
    startDate: Date | null,
    endDate: Date | null,
    associatedQualificationId: number | null
};

class QualificationMultipleEditorPageState extends State {
    qualificationTypes = [] as QualificationType[];
    airlines = [] as string[];
    currentAirline = undefined as string | undefined;
    datesModalOpen = false as boolean;
    enableQualificationType = false as boolean;
    selectedQualificationType = undefined as QualificationType | undefined;
    editorMode = EditorMode.DEFAULT;
    humanResource = undefined as HumanResourceInput | undefined;
}

class QualificationMultipleEditorPageReducers<S extends QualificationMultipleEditorPageState = QualificationMultipleEditorPageState> extends Reducers<S> {

    replaceQualificationType(type: QualificationType) {
        let index = this.s.qualificationTypes.findIndex((qualificationType) => qualificationType.id === type.id);
        this.s.qualificationTypes[index] = type;
        if (this.s.enableQualificationType) {
            this.s.qualificationTypes[index].enabled = true;
        }
    }

    getCurrentAirline() {
        return this.s.currentAirline === OTHERS ? null : this.s.currentAirline;
    }

    deselectCurrentQualificationTypes() {
        this.s.qualificationTypes.map((type) => {
            if (type.airline === this.getCurrentAirline()) {
                type.enabled = false;
                type.startDate = null;
                type.endDate = null;
            }
            return type;
        });
    }

    selectCurrentQualificationTypes(startDate: string | undefined, endDate: string | undefined) {
        this.s.qualificationTypes.map((type) => {
            if (type.airline === this.getCurrentAirline() && !type.enabled) {
                type.enabled = true;
                type.startDate = !startDate ? null : moment(startDate).toDate();
                type.endDate = !endDate ? null : moment(endDate).toDate();
            }
            return type;
        });
    }

    openDatesModalInDefaultMode(selectedQualificationType: QualificationType, enableQualificationType: boolean) {
        this.s.datesModalOpen = true;
        this.s.enableQualificationType = enableQualificationType;
        this.s.editorMode = EditorMode.DEFAULT;
        this.s.selectedQualificationType = selectedQualificationType;
    }

    openDatesModalInMultipleMode() {
        this.s.datesModalOpen = true;
        this.s.editorMode = EditorMode.MULTIPLE;
    }

    closeDatesModal() {
        this.s.datesModalOpen = false;
        this.s.enableQualificationType = false;
        this.s.editorMode = EditorMode.DEFAULT;
        this.s.selectedQualificationType = undefined;
    }

}

type QualificationMultipleEditorProps = RRCProps<QualificationMultipleEditorPageState, QualificationMultipleEditorPageReducers>;

export class QualificationMultipleEditorPage extends React.Component<QualificationMultipleEditorProps> {

    constructor(props: QualificationMultipleEditorProps) {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.updatedQualificationTypeBasedOnHrQualification = this.updatedQualificationTypeBasedOnHrQualification.bind(this);
    }

    async componentDidMount() {
        AppMetaTempGlobals.appMetaInstance.tempLocationForEditorBackground = AppMetaTempGlobals.history.location;

        const urlSearchParams = new URLSearchParams(AppMetaTempGlobals.history.location.search);
        let humanResourceId = urlSearchParams.get(HUMAN_RESOURCE_ID);
        if (humanResourceId) {
            await this.loadHumanResource(Number(humanResourceId));
        }

        await this.loadQualificationTypes();
    }

    componentWillUnmount(): void {
        AppMetaTempGlobals.appMetaInstance.tempLocationForEditorBackground = undefined;
    }

    componentDidUpdate(prevProps: Readonly<QualificationMultipleEditorProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.s.humanResource !== this.props.s.humanResource) {
            this.loadQualificationTypes()
        }
    }

    protected getQualificationsForAirline(airline?: string) {
        let selectedAirline = airline === OTHERS ? null : airline;

        return this.props.s.qualificationTypes.filter((qualification) => qualification.airline === selectedAirline);
    }

    protected checkQualificationValidityBasedOnDates(startDate: Date | null, endDate: Date | null) {
        let startTime = moment(startDate).toDate().getTime();
        let endTime = moment(endDate).toDate().getTime();
        let currentTime = moment().toDate().getTime();

        return (startDate && endDate && startTime < currentTime && endTime > currentTime) ||
            (startDate && !endDate && startTime < currentTime) ||
            (!startDate && endDate && endTime > currentTime);
    }

    protected updatedQualificationTypeBasedOnHrQualification(qualificationType: QualificationType) {
        qualificationType.enabled = false;

        if (!this.props.s.humanResource) {
            return qualificationType;
        }

        let hrQualifications = this.props.s.humanResource.qualifications;
        if (!hrQualifications) {
            return qualificationType;
        }

        let qualificationIndex = hrQualifications.findIndex((hrQualification) => hrQualification?.qualificationType?.id === qualificationType.id)
        if (qualificationIndex === -1) {
            return qualificationType;
        }

        if ((!hrQualifications[qualificationIndex]?.startDate && !hrQualifications[qualificationIndex]?.endDate) ||
            (this.checkQualificationValidityBasedOnDates(hrQualifications[qualificationIndex]?.startDate, hrQualifications[qualificationIndex]?.endDate))) {
            qualificationType.enabled = true;
        }

        qualificationType.startDate = hrQualifications[qualificationIndex]?.startDate;
        qualificationType.endDate = hrQualifications[qualificationIndex]?.endDate;
        qualificationType.associatedQualificationId = hrQualifications[qualificationIndex]?.id;

        return qualificationType;
    }

    protected async loadHumanResource(id: number) {
        let humanResource = (await apolloClient.query({
            query: LOAD_HUMAN_RESOURCE,
            variables: { id }
        })).data["humanResourceService_findById"];

        this.props.r.setInReduxState({ humanResource });
    }

    protected async loadQualificationTypes() {
        let qualificationTypes: QualificationType[] = (await apolloClient.query({
            query: LOAD_QUALIFICATION_TYPES,
            variables: FindByStringParams.create().pageSize(-1).countMode(undefined)
        })).data["qualificationTypeService_findByString"];

        let airlines: string[] = [];
        let addOthers = false;

        qualificationTypes = qualificationTypes.map((qualificationType) => {
            if (qualificationType.airline && !airlines.includes(qualificationType.airline)) {
                airlines.push(qualificationType.airline);
            } else if (!addOthers && !qualificationType.airline) {
                addOthers = true;
            }

            return this.updatedQualificationTypeBasedOnHrQualification(qualificationType);
        });

        airlines.sort();
        if (addOthers) {
            airlines.unshift(OTHERS)
        }
        let currentAirline = airlines[0];

        this.props.r.setInReduxState({ qualificationTypes, airlines, currentAirline });
    }

    protected async onSave() {
        let qualificationIdsForDeleteFilter: number[] = []

        let qualificationsParams = this.props.s.qualificationTypes.reduce((params, qualificationType) => {
            if (qualificationType.enabled) {
                let { enabled, startDate, endDate, associatedQualificationId, ...qualificationTypeLight } = qualificationType;

                params.push({
                    id: associatedQualificationId,
                    duplicateFromId: null,
                    fieldsAndValues: { humanResource: this.props.s.humanResource, qualificationType: qualificationTypeLight, startDate, endDate }
                });
            } else if (!qualificationType.enabled && qualificationType.associatedQualificationId) {
                // In this case, we will delete associated qualifications, by storing ids and then creating
                // a filter to use deleteByFilter
                qualificationIdsForDeleteFilter.push(qualificationType.associatedQualificationId);
            }
            return params;
        }, [] as SaveFieldsParams[]);

        await apolloClient.mutate({ mutation: UPDATE_QUALIFICATIONS, variables: { qualificationsParams } });

        if (qualificationIdsForDeleteFilter.length > 0) {
            await apolloClient.mutate({
                mutation: DELETE_BY_FILTER_QUALIFICATIONS,
                variables: FindByFilterParams.create().filter(Filter.create("id", FilterOperators.forNumber.in, qualificationIdsForDeleteFilter.toString()))
            });
        }

        if (this.props.s.humanResource) {
            await this.loadHumanResource(this.props.s.humanResource.id);
        }
        await this.loadQualificationTypes();
    }

    protected renderDatesModal() {
        return <ModalExt open={this.props.s.datesModalOpen} closeIcon={true} size="small"
            onClose={() => this.props.r.closeDatesModal()}
            header={
                <div>
                    {this.props.s.editorMode === EditorMode.DEFAULT ?
                        _msg("QualificationType.chooseDates.label", this.props.s.selectedQualificationType?.name) :
                        _msg("QualificationType.chooseDatesForCurrentQualifications.label")}
                </div>
            }
            content={
                <ModalContent>
                    <EntityEditorFormSimple entity={this.props.s.editorMode === EditorMode.DEFAULT ? this.props.s.selectedQualificationType : {}}
                        columnsVisibleMap={{ "startDate": true, "endDate": true }} entityDescriptor={entityDescriptors["Qualification"]}
                        onCancelHandler={() => this.props.r.closeDatesModal()} buttonBarPosition="bottom"
                        onSubmitHandler={(entity) => {
                            if (this.props.s.editorMode === EditorMode.DEFAULT) {
                                this.props.r.replaceQualificationType(entity);
                            } else {
                                this.props.r.selectCurrentQualificationTypes(entity.startDate, entity.endDate);
                            }
                            this.props.r.closeDatesModal();
                        }} />
                </ModalContent>
            }
        />;
    }

    protected renderOptions() {
        const fieldDescriptor = new FieldDescriptor();
        fieldDescriptor.name = "humanResource";

        return <>
            <div className="QualificationMultipleEditor_humanResourceSelector">
                <AssociationFieldEditor fieldDescriptor={fieldDescriptor} innerEntityDescriptor={entityDescriptors["HumanResource"]}
                    selectedValue={this.props.s.humanResource} onChange={value => this.props.r.setInReduxState({ humanResource: value })} />
            </div>
            <Popup trigger={<Button icon="plus" content={_msg("Qualification.addQualificationType.label")} onClick={() => {
                AppMetaTempGlobals.history.push(entityDescriptors["QualificationType"].getEntityEditorUrl(ADD));
            }} />}>
                <Icon name="info circle" color="blue" />{_msg("Qualification.multipleEditor.addQualificationTypeInfo.label")}
            </Popup>
            <div className="EntityTablePage_barDivider" />
            <Button primary disabled={!this.props.s.humanResource} content={_msg("general.save")} onClick={this.onSave} />
            <Popup trigger={<Button content={_msg("general.revert")} onClick={async () => { await this.loadQualificationTypes(); }} />}>
                <Icon name="info circle" color="blue" />{_msg("Qualification.multipleEditor.revertInfo.label")}
            </Popup>
        </>
    }

    render(): React.ReactNode {
        return <div className="QualificationMultipleEditor_container flex-container flex-grow">
            <Segment className="buttonBar flex-center">
                {this.renderOptions()}
            </Segment>
            <Segment className="QualificationMultipleEditor_editorContainer flex flex-grow">
                <SplitPaneExt defaultSize="20%" className="QualificationMultipleEditor_splitPaneExt">
                    <Menu className="QualificationMultipleEditor_airlinesList" vertical>
                        {this.props.s.airlines.map((airline) => {
                            let types = this.getQualificationsForAirline(airline);

                            return <MenuItem key={airline} active={airline === this.props.s.currentAirline} content={airline + " (" + types.filter((type) => type.enabled).length + "/" + types.length + ")"}
                                onClick={() => { this.props.r.setInReduxState({ currentAirline: airline }); }} />
                        })}
                        {/* Add a placeholder in order to have a uniform design for other menu items (without it, last menu item would have a missing bottom border) */}
                        <MenuItem key="placeholder" />
                    </Menu>
                    <div className="flex-container flex-grow">
                        <QualificationTypeCustomTableSimpleRRC id={"qualificationTypeTableSimple"} entityDescriptor={qualificationTypeEntityDescriptor}
                            entitiesAsParams={this.getQualificationsForAirline(this.props.s.currentAirline)}
                            columns={qualificationTypeTableSimpleColumns} replaceQualificationType={(qualificationType) => this.props.r.replaceQualificationType(qualificationType)}
                            openDatesModalInDefaultMode={(selectedQualificationType, enableQualificationType) => this.props.r.openDatesModalInDefaultMode(selectedQualificationType, enableQualificationType)} />
                        <Segment>
                            <Button icon="plus" content={_msg("Qualification.selectAll.label")} positive onClick={() => {
                                this.props.r.openDatesModalInMultipleMode();
                            }} />
                            <Button icon="close" content={_msg("Qualification.deselectAll.label")} negative onClick={() => {
                                this.props.r.deselectCurrentQualificationTypes();
                            }} />
                        </Segment>
                    </div>
                </SplitPaneExt>
            </Segment>
            {this.renderDatesModal()}
        </div>;
    }

}

export const QualificationMultipleEditorPageRRC = ReduxReusableComponents.connectRRC(QualificationMultipleEditorPageState, QualificationMultipleEditorPageReducers, QualificationMultipleEditorPage);

export const qualificationMultipleEditorPageTab = {
    routeProps: { path: QUALIFICATION_MULTIPLE_EDITOR_PATH },
    menuItemProps: { icon: "check circle outline", content: _msg("Qualification.multipleEditor.label") },
    render: () => <QualificationMultipleEditorPageRRC id="qualificationMultipleEditor" />
};