import React, { ReactNode, ReactElement } from "react";
import { DummyToRememberPeopleToCast, FieldDescriptor } from "@crispico/foundation-react";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { DrivingEventVideoButtonRRC } from "./DrivingEventVideoButton";
import { IFormValuesHolder } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { CUSTOM_FIELDS } from "@crispico/foundation-react/entity_crud/FieldType";
import { equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import { Input } from "semantic-ui-react";

export class DrivingEvent_FieldDescriptor extends FieldDescriptor {

    constructor(name: string) {
        super();
        this.name = name;
    }

    getAppearsInUi(): boolean {
        return true;
    }

    getFieldDescriptorFromEquipmentResource(name: string): FieldDescriptor {
        const eqFieldDescriptorChain = equipmentResourceEntityDescriptor.getFieldDescriptorChain(name);
        return eqFieldDescriptorChain[eqFieldDescriptorChain.length - 1];
    }
}

export class DrivingEvent_VideoFieldDescriptor extends DrivingEvent_FieldDescriptor {

    constructor() {
        super("video");
        this.icon = "film";
        this.clientOnly = true;
        this.filterable = false;
        this.sortable = false;
        this.isInDefaultColumnConfigForEditor = false;
    }

    protected renderFieldInternal(_: any, props: FieldRendererProps): ReactNode {
        return <DrivingEventVideoButtonRRC id={"VideoFieldRendererField_" + props.entity.id} drivingEventId={props.entity.id} />
    }
}

export class DrivingEvent_ValueFieldDescriptor extends DrivingEvent_FieldDescriptor {

    constructor() {
        super("value");
    }

    adjustEntity(entity: any, fd: FieldDescriptor) {
        const newEntity = { [entity.type]: entity.value };
        return fd.isCustomField ? { [CUSTOM_FIELDS]: newEntity } : newEntity;
    }

    renderField(entity: any, additionalFieldRendererProps?: DummyToRememberPeopleToCast) {
        const fd = this.getFieldDescriptorFromEquipmentResource(entity.type);
        return fd ? fd.renderField(this.adjustEntity(entity, fd), additionalFieldRendererProps)
            : super.renderField(entity, additionalFieldRendererProps)
    }

    renderFieldEditor(formikProps: IFormValuesHolder<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast): ReactElement {
        const fd = this.nameBeforeCopyToFilter ? null : this.getFieldDescriptorFromEquipmentResource(formikProps.values.type);
        return fd ? fd.renderFieldEditor({ ...formikProps, values: { ...formikProps.values, ...this.adjustEntity(formikProps.values, fd) } }, additionalFieldEditorProps)
            : super.renderFieldEditor(formikProps, additionalFieldEditorProps);
    }
}

export class DrivingEvent_MeasurementUnitFieldDescriptor extends DrivingEvent_FieldDescriptor {

    constructor() {
        super("measurementUnit");
        this.clientOnly = true;
    }

    getFieldValue(values: any, forEditor: boolean = false) {
        const fd = this.getFieldDescriptorFromEquipmentResource(values.type);
        return fd ? fd.getMeasurementUnitLabel(forEditor) : undefined;
    }

    renderFieldEditor(formikProps: IFormValuesHolder<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast): ReactElement {
        return <Input value={this.getFieldValue(formikProps.values, true)} disabled />
    }
}

export class DrivingEvent_TypeFieldDescriptor extends DrivingEvent_FieldDescriptor {

    constructor() {
        super("type");
    }

    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps): ReactNode {
        const label = this.getFieldDescriptorFromEquipmentResource(props.entity[this.name])?.getLabel(false, false);
        return label ? <>{label}</> : super.renderFieldInternal(RendererClass, props, props.entity);
    }
}
