import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, EntityTablePage, EntityTableSimple, EntityTableSimpleReducers, EntityTableSimpleState, ENT_ADD, ENT_DELETE, ENT_SAVE, Optional, Organization, Utils, EntityTablePageState, EntityTablePageReducers, EntityTablePagePartialProps } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { EntityToTagFieldDescriptor } from "@crispico/foundation-react/pages/EntityToTag/entityToTagDescriptor";
import { Upload } from "antd";
import { UploadChangeParam, UploadProps } from "antd/lib/upload";
import { SaveParams_LongInput } from "apollo-gen/globalTypes";
import { loadTerritoriesForMap, loadTerritoriesForMapVariables } from "apollo-gen/loadTerritoriesForMap";
import { TerritoryForMap } from "apollo-gen/TerritoryForMap";
import { EditMode, ID, Location, MapContainerLeafletRRC, MapContainerLeaflet, PolygonData, POLYGON_TYPE, SelectedLayer } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { DEFAULT_POLYGON_COLOR } from "components/MapContainerLeaflet/MapLayerHelpers";
import gql from "graphql-tag";
import Interweave from "interweave";
import lodash from "lodash";
import { SAVE_TERRITORIES_FOR_MAP } from "pages/Territory/queries";
import React from "react";
import { Button, Confirm, Dimmer, Form, Icon, Loader, Message, Modal, Segment, TextArea } from "semantic-ui-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { MapGoToButton, RealTimeUtils } from "components/realTimeMap/RealTimeUtils";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { AppContainerContext } from "@crispico/foundation-react/AppContainerContext";
import { XopsAppContainerContextValue } from "XopsAppContainerContext";
import { LOAD_TERRITORIES_FOR_REAL_TIME_MAP } from "components/realTimeMap/queries";
import { suppressDeprecationWarnings } from "moment-timezone";
import _ from "lodash";
import { organizationEntityDescriptor } from "@crispico/foundation-react/pages/SettingsEntity/settingsEntityDescriptor";

const NUMBER_OF_TERRITORIES_FOR_MAP = 1000;

export class TerritoryEntityDescriptor extends EntityDescriptor {

    constructor() {
        super({
            name: "Territory",
            miniFields: ["name"],
            icon: "object ungroup outline",
            defaultSort: { field: "name", direction: "ASC" }
        });
    }

    renderTable() {
        return <TerritoryTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
    }
}

export const territoryEntityDescriptor = addEntityDescriptor(new TerritoryEntityDescriptor()
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
    .addFieldDescriptor({ name: "description", type: FieldType.string })
    .addFieldDescriptor({ name: "color", type: FieldType.color })
    .addFieldDescriptor({ name: "organization", type: "Organization" })
    .addFieldDescriptor(new EntityToTagFieldDescriptor())
);

class TerritoryTablePageState extends EntityTablePageState {
    territories: Optional<{ [key: string]: TerritoryForMap }> = undefined;
    importKMLInfo: Optional<{ status?: string, showConfirmation?: boolean, polygons?: PolygonData[] }> = undefined;
    showModal: boolean = false;
    openEditor: boolean = false;
}

class TerritoryTablePageReducers<S extends TerritoryTablePageState = TerritoryTablePageState> extends EntityTablePageReducers<S> {

    add(newTerritories: TerritoryForMap[]) {
        if (!this.s.territories) {
            this.s.territories = {};
        }
        newTerritories.forEach(newTerr => {
            this.s.territories![newTerr.id] = newTerr;
        });
    }

    remove(ids: ID[]) {
        ids.forEach(id => {
            delete this.s.territories![id];
        });
    }
}

type TerritoryTablePageProps = EntityTablePagePartialProps & RRCProps<TerritoryTablePageState, TerritoryTablePageReducers>;
class TerritoryTablePage<P extends TerritoryTablePageProps = TerritoryTablePageProps> extends EntityTablePage<P> {

    static contextType = AppContainerContext;
    context!: XopsAppContainerContextValue;

    static defaultProps = {
        ...EntityTablePage.defaultProps,
        itemsHidedFromCell: ["add"]
    }

    async loadTerritoriesOnMap(ids?: Optional<ID[]>) {
        let filterFromCQ = this.getFilterForLoad();

        let filter: Filter;
        if (ids && ids.length > 0) {
            const filters: Filter[] = [];
            filters.push(filterFromCQ);
            filters.push(Filter.create("id", FilterOperators.forNumber.in, ids.join(", ")));
            filter = Filter.createComposed(FilterOperators.forComposedFilter.and, filters);
        } else {
            filter = filterFromCQ;
        }

        const territories: Optional<TerritoryForMap[]> = (await apolloClient.query({
            query: LOAD_TERRITORIES_FOR_REAL_TIME_MAP,
            variables: { filter: filter, coordinates: this.mapContainerRef.current?.getCurrentBounds() },
            context: { showSpinner: false }
        })).data.territoryService_findByRectangle?.results;

        if (!territories || territories.length === 0) {
            this.props.r.add([]);
            return;
        }

        if (territories.length <= NUMBER_OF_TERRITORIES_FOR_MAP) {
            this.props.r.add(territories);
            this.addTerritoriesOnMap(territories);
        } else {
            this.props.r.add([]);
            this.props.r.setInReduxState({ showModal: true });
        }
    }

    addTerritoriesOnMap(territories: TerritoryForMap[]) {
        let polygons: PolygonData[] = [];
        territories.forEach((territory: TerritoryForMap) => {
            const points: Location[] = [];
            territory.coordinates?.forEach(p => {
                points.push({ longitude: p.a, latitude: p.b });
            });
            polygons.push({ id: territory.id, points: points, color: territory.color || undefined, text: territory.name || undefined });
        });
        this.mapContainerRef.current?.addOrUpdateLayers(polygons, territoryEntityDescriptor.name);
    }

    isAllowed(editMode: EditMode): boolean {
        const mode = editMode === EditMode.DRAW ? ENT_ADD : editMode === EditMode.EDIT ? ENT_SAVE : ENT_DELETE;
        const permission = Utils.pipeJoin([mode, territoryEntityDescriptor.name]);
        if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission)) {
            return false;
        }
        return true;
    }

    async updateTerritories(polygons: PolygonData[], org?: Organization) {
        let ids: number[] = [];

        let params: SaveParams_LongInput[] = [];
        for (const polygon of polygons) {
            let territory = polygon.id ? { ...this.props.s.territories![polygon.id] } : { name: polygon.text, color: polygon.color } as TerritoryForMap;
            territory.coordinates = [];
            polygon.points.forEach(p => territory.coordinates?.push({ a: p.longitude, b: p.latitude }));
            let fieldsAndValue: any = {
                name: territory.name,
                color: territory.color ? territory.color : DEFAULT_POLYGON_COLOR,
                coordinates: territory.coordinates,
            };
            if (org) {
                fieldsAndValue['organization'] = { id: org?.id }
            }
            params.push({ id: territory.id, fieldsAndValues: fieldsAndValue });
        }
        const result: [{ id: number }] = (await apolloClient.mutate({ mutation: SAVE_TERRITORIES_FOR_MAP, variables: { params: params } })).data.territoryService_saveAll;
        result.forEach(t => ids.push(t.id));

        // refresh the table
        this.refresh();

        // update only modified & added features
        await this.loadTerritoriesOnMap(ids);

        if (polygons.length === 1 && polygons[0].id === undefined) {
            this.mapContainerRef.current?.props.r.setInReduxState({ selectedLayer: { id: ids[0], type: territoryEntityDescriptor.name } });
        }
    }

    async removeTerritories(polygons: PolygonData[]) {
        const permission = Utils.pipeJoin([ENT_DELETE, territoryEntityDescriptor.name]);
        if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission, true)) {
            return;
        }
        const removeOperationName = `${lodash.lowerFirst(territoryEntityDescriptor.name)}Service_deleteById`;
        const removeMutation = gql(`mutation deleteEntity($id: Long){${removeOperationName}(id: $id)}`);
        let ids: ID[] = [];
        for (const polygon of polygons) {
            ids.push(polygon.id!);
            await apolloClient.mutate({ mutation: removeMutation, variables: { id: polygon.id } });
        }
        // refresh the table
        this.refresh();

        this.props.r.remove(ids);
    }

    mapContainerRef = React.createRef<MapContainerLeaflet>();

    constructor(props: P) {
        super(props);
        this.renderTooltipContent = this.renderTooltipContent.bind(this);
        const that = this;
        this.tableSimpleClass = ReduxReusableComponents.connectRRC(EntityTableSimpleState, EntityTableSimpleReducers, class extends EntityTableSimple {
            onSelectItem(selectId: any) {
                that.mapContainerRef.current!.props.r.setInReduxState({ selectedLayer: { id: selectId, type: territoryEntityDescriptor.name } });
            }
        });
    }

    componentDidUpdate(prevProps: P) {
        super.componentDidUpdate(prevProps);

        // set this here instead of componentDidMount, because ref is null there
        if (!this.mapContainerRef?.current?.props.s.editDrawEnabledOnType) {
            this.mapContainerRef?.current?.props.r.setInReduxState({ editDrawEnabledOnType: territoryEntityDescriptor.name, editMode: undefined });
        }

        // verify if [0, 0] -> default value; if not [0, 0], then it means the center was set by value from session storage, so we don't want to reset it
        if (this.mapContainerRef.current && lodash.isEqual(this.mapContainerRef.current!.props.s.center, [0, 0]) && this.context.initializationsForClient.mapSettings?.airport !== null) {
            RealTimeUtils.setAirportCoordinates(this.context.initializationsForClient.mapSettings.airport, this.mapContainerRef);
        }

        if (this.props.s.totalCount != prevProps.s.totalCount) {
            this.mapContainerRef.current?.clearMap();
            this.loadTerritoriesOnMap();
        }

        if (this.props.s.openEditor != prevProps.s.openEditor) {
            const selectedId = this.mapContainerRef.current?.props.s.selectedLayer?.id;
            if (selectedId) {
                this.goToEditor(territoryEntityDescriptor.getEntityEditorUrl(selectedId));
            }           
            this.props.r.setInReduxState({ openEditor: false });
        }
    }

    renderTooltipContent(layerData: PolygonData, type: string, additionalInfo?: { pointId?: ID }): React.ReactElement {
        return <><div>{layerData.text}</div> {layerData.readableArea}</>;
    }

    protected onCustomQueryBarChanged() {
        this.props.r.setInReduxState({ territories: undefined });
        this.entityTableSimpleRef.current?.setSelected(undefined);
        this.mapContainerRef.current?.clearMap();
        this.loadTerritoriesOnMap();
        super.onCustomQueryBarChanged();
    }

    protected preRenderButtons(params: any): Array<OverrideableElement> {
        const props: UploadProps = {
            onChange: (info: UploadChangeParam) => {
                const file = info.fileList[info.fileList.length - 1];
                if (file.status !== 'uploading') {
                    this.props.r.setInReduxState({ importKMLInfo: { status: "inProgress" } });
                    let reader = new FileReader();
                    reader.onload = async (e) => {
                        if (e.target) {
                            const polygons: PolygonData[] = this.mapContainerRef.current!.parseKML(e.target.result as string);
                            if (polygons.length === 0) {
                                AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.showGlobalAlert({ message: _msg('Territory.importKMLFile.none') });
                                this.props.r.setInReduxState({ importKMLInfo: undefined })
                            } else {
                                this.props.r.setInReduxState({ importKMLInfo: { status: "inProgress", showConfirmation: true, polygons: polygons } });
                            }
                        }
                    }
                    if (file.originFileObj) {
                        reader.readAsText(file.originFileObj as Blob);
                    }
                }
                if (file.status === 'error') {
                    Utils.showGlobalAlert({ message: _msg('Territory.importKMLFile.error') });
                }
            },
            multiple: false,
            accept: ".kml",
            showUploadList: false
        };
        return [
            ...super.preRenderButtons(params),
            {
                element:
                    <div key="selectAirportAndImportKML" className="flex-container-row MapContainerHeader_segment">
                        <Dimmer inverted active={this.props.s.territories === undefined}></Dimmer>

                        <Upload  {...props} disabled={this.props.s.importKMLInfo?.status === "inProgress"} beforeUpload={() => false}>
                            <Button disabled={this.props.s.importKMLInfo?.status === "inProgress"} color="blue"><Icon loading={this.props.s.importKMLInfo?.status === "inProgress"} name={this.props.s.importKMLInfo?.status === "inProgress" ? 'spinner' : 'upload'} />
                                {this.props.s.importKMLInfo?.status === "inProgress" ? _msg("Territory.importKMLFile.inProgress", this.props.s.importKMLInfo.polygons?.length) : _msg("Territory.importKMLFile")}
                            </Button>
                            <Confirm
                                open={this.props.s.importKMLInfo?.showConfirmation === true}
                                header={_msg("Territory.importKMLFile")}
                                content={() => {
                                    let names = "";
                                    this.props.s.importKMLInfo?.polygons?.forEach(p => names += p.text + "\n");
                                    return <Form>
                                        <TextArea rows="10">{_msg("Territory.importKMLFile.info", names)}</TextArea>
                                    </Form>
                                }}
                                onCancel={() => this.props.r.setInReduxState({ importKMLInfo: undefined })}
                                onConfirm={async () => {
                                    this.props.r.setInReduxState({ importKMLInfo: { ...this.props.s.importKMLInfo, showConfirmation: false } });
                                    await this.updateTerritories(this.props.s.importKMLInfo?.polygons!, this.context.initializationsForClient.currentOrganization || undefined);
                                    this.props.r.setInReduxState({ importKMLInfo: undefined });
                                }}
                                cancelButton={_msg("general.cancel")}
                                confirmButton={_msg("general.ok")}
                            />
                        </Upload>
                        <MapGoToButton options={RealTimeUtils.getMapGoToButtonProps(this.mapContainerRef)} />
                    </div>
            },
            
        ];
    };

    renderMain() {
        return (<>
            <Utils.Observer value={this.context.initializationsForClient.currentOrganization} didUpdate={() => {
                RealTimeUtils.selectAirportOnCurrentOrganizationToFilterByChange(this.mapContainerRef, this.context.initializationsForClient.currentOrganization);
            }} />
          
          <SplitPaneExt size="30%">
                {this.mapContainerRef.current?.props.s.editMode
                    ? <Message data-cy="editModeMessage" className="flex-container flex-grow">
                        <Interweave content={_msg("Territory." + this.mapContainerRef.current!.props.s.editMode + "Mode")} />
                    </Message>
                    : super.renderMain()}
                <>              
                    <Dimmer inverted active={this.props.s.territories === undefined}><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>

                    <MapContainerLeafletRRC id={"mapContainerLeafletTerritoryTable"} key="mapContainerLeafletTerritoryTable" ref={this.mapContainerRef} mapId={"territory-map"}
                        layers={{ [territoryEntityDescriptor.name]: { layerType: POLYGON_TYPE, options: { flyToSelectedMarker: true, hideTooltipOnHoveredLayer: false } } }}
                        pruneClusterMode={false}
                        azureMapsAPIKey={this.context.initializationsForClient.mapSettings.azureMapsAPIKey}
                        bingAPIKey={this.context.initializationsForClient.mapSettings.bingAPIKey}
                        isAllowed={(editMode: EditMode) => { return this.isAllowed(editMode) }}
                        onLayerAdded={(polygon: PolygonData, type: string) => this.updateTerritories([polygon], this.context.initializationsForClient.currentOrganization || undefined)}
                        onLayersEdited={(polygons: PolygonData[], type: string) => this.updateTerritories(polygons, this.context.initializationsForClient.currentOrganization || undefined)}
                        onLayersRemoved={(polygons: PolygonData[], type: string) => this.removeTerritories(polygons)}
                        renderTooltipContent={this.renderTooltipContent} saveCenterZoomInStorage={true} 
                        onEditModeChanged={() => this.forceUpdate()}
                        onCoordinatesChanged={() => this.loadTerritoriesOnMap()}
                        onDoubleClickLayer={(prevValue: Optional<SelectedLayer>) => this.props.r.setInReduxState({ openEditor: true })} />
                    <ModalExt open={this.props.s.showModal} onClose={() => this.props.r.setInReduxState({ showModal: false })}>
                        <Modal.Content className="wh100">
                            <Modal.Description><h1>{_msg("Territory.tooManyTeritoriesForMap")}</h1></Modal.Description>
                        </Modal.Content>
                    </ModalExt>
                </>
            </SplitPaneExt>
        </>)
    }
}

const TerritoryTablePageRRC = ReduxReusableComponents.connectRRC(TerritoryTablePageState, TerritoryTablePageReducers, TerritoryTablePage);