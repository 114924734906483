// WARNING: if you do "organize imports", make sure that this remains on top of the file!
import "./apollo-gen-crud/entitiesGen";

import { Messages } from "@crispico/foundation-gwt-js";
import { AppMeta, CompMeta, ConnectedComponentInSimpleComponent, ConnectedPageHelper, ConnectedPageInfo, EntityDescriptor, FoundationInitializationsForClient, searchOptions, Utils, Optional, Organization, MenuEntry, User, ENT_TABLE, ENT_READ, PHONE_MODE_KEY, AppContainer, infoAppContainer } from '@crispico/foundation-react';
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { infoTemplateConnectedComponent } from '@crispico/foundation-react/demo/pages/_TemplateConnectedComponent/TemplateConnectedComponent';
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { dashboardEntityDescriptor } from "@crispico/foundation-react/FoundationEntityDescriptors";
import { auditEntityDescriptor } from "@crispico/foundation-react/pages/Audit/auditEntityDescriptor";
import * as dashboardZt from "@crispico/foundation-react/pages/dashboard/dashboardTab/stories";
import { DashboardWidgetEntityDescriptor, DashboardWidgetFactories, DashboardWidgetFactory, DashboardWidgetType } from "@crispico/foundation-react/pages/dashboard/DashboardWidgetFactory";
import { entityToTagEntityDescriptor, tagEntityDescriptor } from '@crispico/foundation-react/pages/EntityToTag/entityToTagDescriptor';
import { HomePage, infoHomePage } from '@crispico/foundation-react/pages/HomePage';
import { infoLoginPage } from "@crispico/foundation-react/pages/loginPage/LoginPage";
import { notificationEntityDescriptor, problemEntityDescriptor } from "@crispico/foundation-react/pages/notification/notificationEntityDescriptor";
import { infoPasswordResetFinishPage } from "@crispico/foundation-react/pages/passwordReset/PasswordResetFinishPage";
import { infoPasswordResetInitPage } from "@crispico/foundation-react/pages/passwordReset/PasswordResetInitPage";
import { infoPersonalHomePage } from "@crispico/foundation-react/pages/PersonalHomePage";
import { roleEntityDescriptor, roleToUserEntityDescriptor } from "@crispico/foundation-react/pages/role/roleEntityDescriptor";
import * as roleZt from '@crispico/foundation-react/pages/role/stories';
import { scheduledTaskEntityDescriptor } from "@crispico/foundation-react/pages/ScheduledTasks/ScheduledTaskEntityDescriptor";
import { organizationEntityDescriptor, settingsEntityDescriptor } from '@crispico/foundation-react/pages/SettingsEntity/settingsEntityDescriptor';
import { zeroTrainingIndexPageMenuEntry } from '@crispico/foundation-react/zeroTraining/ZeroTrainingIndexPage';
import { addressEntityDescriptor, AppEntityDescriptors, blocklyScriptEntityDescriptor, humanResourceEntityDescriptor, qualificationEntityDescriptor, flightEntityDescriptor, missionEntityDescriptor, baggageEntityDescriptor, chartEntityDescriptor, ganttAssignmentEntityDescriptor, taskEntityDescriptor, detectionEventEntityDescriptor } from "AppEntityDescriptors";
import { MapWidgetRRC } from "components/editorWidgets";
import { MARKER_TYPE } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { CLUSTER_MODE, MAP_CLUSTER_MODE_KEY, RealTimeMapRRC } from "components/realTimeMap/RealTimeMap";
import { VehicleInformationWidget } from "components/VehicleInformationWidget";
import { push } from "connected-react-router";
import 'font-awesome/css/font-awesome.min.css';
import { checklistEntityDescriptor } from 'pages/Checklist/checklistEntityDescriptor';
import { drivingEventEntityDescriptor } from "pages/DrivingEvent/drivingEventEntityDescriptor";
import {
    airportEntityDescriptor, emissionRatingEntityDescriptor, equipmentResourceEntityDescriptor, equipmentTypeEntityDescriptor,
    telemetryEventMappingEntityDescriptor, telemetrySystemEntityDescriptor, unitEntityDescriptor
} from 'pages/EquipmentResource/equipmentResourceEntityDescriptor';
import { flightScheduleEntityDescriptor } from "pages/flightSchedule/flightScheduleEntityDescriptor";
import { historicalMapAnalysisEntityDescriptor } from 'pages/HistoricalMapAnalysis/historicalMapAnalysisEntityDescriptor';
import { inventoryHistoryDataEntityDescriptor } from 'pages/InventoryPage/inventoryHistoryDataEntityDescriptor';
import { positionEntityDescriptor } from "pages/Position/positionEntityDescriptor";
import { infoScanBaggageFormPage } from "pages/ScanBaggage/ScanBaggageFormPage";
import { territoryEntityDescriptor } from 'pages/Territory/territoryEntityDescriptor';
import "pages/XopsPersonalHomePage";
import React, { RefObject } from 'react';
import { NavLink } from "react-router-dom";
import { Card, Checkbox, Icon, Menu, SemanticCOLORS } from 'semantic-ui-react';

// WARNING: if you do "organize imports", make sure this is remains at the bottom so that the styles are applied.
import 'xops6.css';
import { WidgetProps } from "@crispico/foundation-react/pages/dashboard/dashboardTab/WidgetWrapper";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { XopsLoginPage, XopsLoginTypeEnum } from "pages/XopsLoginPage";
import { xopsMobileWrapperBackboneRoutes, xopsMobileWrapperPageUrlPageMenuEntry, xopsMobileWrapperBackbonePublicRoutes, w, xopsMobileWrapperSettingsMenuEntry, XopsMobileWrapper, XOPS_MOBILE_PATH, XOPS_MOBILE_MISSIONS_PATH } from "pages/XopsMobile/XopsMobileWrapper";
import { missionsAwaitingForDriversPageRoute, missionsAwaitingForDriversPageMenuEntry } from "pages/Mission2/Mission2EntityDescriptor";
import { DiagnosticTroubleCodeWidgetRRC } from "pages/EquipmentResource/DiagnosticTroubleCodeWidget";
import { WidgetStatus } from "@crispico/foundation-react/pages/dashboard/AbstractWidgetWithFilter";
import { fileBrowserPageMenuEntry, fileBrowserPageRoute } from "@crispico/foundation-react/pages/FileBrowser/FileBrowserPage";
import { ganttAssignmentFromFilesOnDiskPageMenuEntry, ganttAssignmentFromFilesOnDiskPageRoute } from "pages/ganttAssignment/GanttAssignmentFromFilesOnDiskPage";
import { GanttAssignmentEntityDescriptor } from "pages/ganttAssignment/GanttAssignmentEntityDescriptor";
import { EntityFilterMode } from "@crispico/foundation-react/entity_crud/fieldEditors/FilterFieldEditor";
import { mobileDeviceEntityDescriptor } from "pages/MobileDevice/mobileDeviceEntityDescriptor";
import { bluetoothDevicesPageMenuEntry, bluetoothDevicesPageRoute } from "pages/BluetoothDevices/BluetoothDevicesPage";
import { realTimeGanttPageMenuEntry, realTimeGanttPageRoute } from "pages/gantt/RealTimeGanttPage";
import { HistoryGraphItemFactory } from "@crispico/foundation-react/pages/Audit/historyGraphItem/HistoryGraphItemFactory";
import { HistoryGraphMapItemRRC } from "pages/EquipmentResource/HistoryGraphMapItem";
import { assignTasksToResourcePageFlexRoute, assignTasksToResourcePageMenuEntry, assignTasksToResourcePageRoute } from "pages/gantt/AssignTasksToResourcePage";
import { RRC_SINGLETON } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { XopsColorRegistry, XopsColorRegistryRRC } from "pages/XopsColor/XopsColorRegistry";
import { missionEventValidationPageMenuEntry, missionEventValidationPageRoute } from "pages/Mission2/MissionEventValidationPage";
import { AssociationStringFieldEditorFieldDescriptor } from "components/AssociationStringFieldEditor";
import { DashboardTab } from "@crispico/foundation-react/pages/dashboard/dashboardTab/DashboardTab";
import { RealTimeMapWidget } from "components/RealTimeMapWidget";

let userEntityDescriptor: EntityDescriptor;
import("@crispico/foundation-react/pages/user/userEntityDescriptor").then(p => {
    userEntityDescriptor = p.userEntityDescriptor;
    userEntityDescriptor.addFieldDescriptor({ name: "units", type: FieldType.string }, AssociationStringFieldEditorFieldDescriptor("Unit"));
    userEntityDescriptor.addFieldDescriptor({ name: "screen", type: "Perspective" });
    userEntityDescriptor.addFieldDescriptor({ name: "roles", type: FieldType.oneToMany("XopsRole") });
});

const mailto = 'mailto:xops@resonate-mp4.com?subject=' + _msg('Menu.ticket.email.title') + '&body=' + encodeURIComponent(_msg('Menu.ticket.email.body'))

ConnectedPageHelper.tempEnableSecurity = true;
Utils.DEFAULT_TEXT_COLOR = '#3A527B'; // same as the one from xops6.css (blue color)

const ELA_RFID_ANTENNA_FULL: string = "ELA_RFID_ANTENNA_FULL";
export const VIDEO_SHOW_PAGE: string = "VIDEO_SHOW_PAGE";
export const VIDEO_ALLOW_VIEW_BLURRED: string = "VIDEO_ALLOW_VIEW_BLURRED";
export const VIDEO_ALLOW_VIEW_ORIGINAL: string = "VIDEO_ALLOW_VIEW_ORIGINAL";
export const VIDEO_ALLOW_LIVE_FEED: string = "VIDEO_ALLOW_LIVE_FEED";

const FLEX_MODE: string = "flexMode";

/**
 * Used to set dummy strings to InitializationsForClient so we can work with AppMeta routes even if 
 * we don't have them yet (needed for XopsMobile).
 */
const DUMMY_STRING: string = 'DUMMY';
export interface MapFields {
    name: string,
    fields: string
}

export interface MarkerSettings {
    markerIdField: string,
    markerType: string,
    showTextUnderIcon: boolean,
    colors: {
        name: string;
        area: string;
        field: string;
        showInFilterBar: string;
        useDurationBetweenNowAndFieldValue: boolean;
        intervals: {
            label?: string;
            from: string;
            to?: string;
            color: string;
        }[];
    }[]
    bigPopupFields: MapFields[]
    smallPopupFields: MapFields[]
    rowFields: MapFields[]
}

export interface MapSettings {
    airport: string,
    bingAPIKey?: string,
    azureMapsAPIKey?: string,
    markers: MarkerSettings[]
}

export interface ConveyorEjectSettings {
    conveyorEject: string,
    color?: string,
    parkings: [{ id: number }]
}
export interface TempUnitSettings {
    organizationId?: number,
    missionTypesToDisplay?: [{ id: number }],
    defaultTripTimeToParking?: number;
    defaultLCOffset?: number;
    defaultMCOffset?: number;
    defaultSCOffset?: number;
}

export interface BaggageStatusSettings {
    name: string,
    label: string,
    color: SemanticCOLORS,
    displayMode: number
}

export enum MissionsAwaitingForDriversPageRendererMode {
    DEFAULT = "DEFAULT",
    BAGGAGE_LAST_MINUTE = "BAGGAGE_LAST_MINUTE"
}
export interface TempMissionTypeSettings {
    missionType: number,
    startAddresses?: [{ id: number }],
    missionsAwaitingForDriversPageRendererMode: MissionsAwaitingForDriversPageRendererMode,
    setObjectEndAddressFromFlightConnectionParking?: boolean
}
export interface TempSettingsXops {
    tempUnitSettings: TempUnitSettings[],
    tempFlightConveyorEjectSettings: ConveyorEjectSettings[],
    tempBaggageStatusSettings: BaggageStatusSettings[],
    tempMissionTypeSettings: TempMissionTypeSettings[],
    displayForSas: boolean
}

export interface FlightSettings {
    rotationFlightOffset: number,
    findByStringFlightDateOffsetHours: number
}

export interface XopsInitializationsForClient extends FoundationInitializationsForClient {
    mapSettings: MapSettings,
    tempSettingsXops: TempSettingsXops,
    flightSettings: FlightSettings,
    currentHumanResource: any /* this should have a type*/,
    hasLongStorage: boolean
}
export class XopsAppMeta extends AppMeta {

    constructor(public metas: CompMeta<any, any>[]) {
        super(metas);

        this.globalPermissions.add(ELA_RFID_ANTENNA_FULL);
        this.globalPermissions.add(VIDEO_SHOW_PAGE);
        this.globalPermissions.add(VIDEO_ALLOW_VIEW_BLURRED);
        this.globalPermissions.add(VIDEO_ALLOW_VIEW_ORIGINAL);
        this.globalPermissions.add(VIDEO_ALLOW_LIVE_FEED);

        this.routes.push(...xopsMobileWrapperBackboneRoutes());
        this.routes.push(...xopsMobileWrapperBackbonePublicRoutes());
        this.routes.push(missionsAwaitingForDriversPageRoute());
        this.routes.push(fileBrowserPageRoute());
        this.routes.push(bluetoothDevicesPageRoute());
        this.routes.push(ganttAssignmentFromFilesOnDiskPageRoute());
        this.routes.push(realTimeGanttPageRoute());
        this.routes.push(assignTasksToResourcePageRoute());
        this.routes.push(missionEventValidationPageRoute());
        this.routes.push(assignTasksToResourcePageFlexRoute());
        this.routes.push(scheduledTaskEntityDescriptor.renderTableRoute());

        this.showSearchBarOnTitleBar = true;
        this.showClock = true;
        if (XopsMobileWrapper.isDeviceMobileApp()) {
            this.showBackButton = true;
            this.showReloadButton = true;
            this.showHomeButton = false;
            this.showSearchBarOnTitleBar = false;
            this.showNotifications = false;
            this.showTimeZone = false;
            this.showOrganization = false;
            this.showHeaderLogo = false;
        }

        const phoneMode: boolean = localStorage.getItem(PHONE_MODE_KEY) == "true";
        if (phoneMode) {
            this.showSearchBarOnTitleBar = false;
            this.showTimeZone = false;
            this.showClock = false;
            this.showHomeButton = false;
            this.showHeaderLogo = false;
        }
    }

    protected hasHomePageMenuEntry() {
        return !XopsMobileWrapper.isDeviceMobileApp();
    }

    canCallOnLocationChangedFromAppContainerConstructor(): boolean {
        return !XopsMobileWrapper.isDeviceMobileApp();
    }

    public getIsSpecialPageWithoutHeader() {
        let displayForSas = this.getDisplayForSas();
        return super.getIsSpecialPageWithoutHeader() || Boolean(window.localStorage.getItem(FLEX_MODE)) || (isIframe() && displayForSas);
    }

    public async loadInitializationsForClient_forMobile() {
        await super.loadInitializationsForClient_fromConstructor();
    }

    protected async loadInitializationsForClient_fromConstructor() {
        if (XopsMobileWrapper.isDeviceMobileApp()) {
            if (Utils.isNullOrEmpty(window.sessionStorage.getItem("login.type"))) {
                window.sessionStorage.setItem("login.type", XopsLoginTypeEnum.HRLOGIN);
            }
            // this was used because in this moment helperAppContainer wasn't initialized yet;
            // need to find another way to do it
            setTimeout(async () => {
                console.log("isMobileApp, set default props!");
                this.helperAppContainer.dispatchers.setInReduxState({
                    initializationsForClientLoaded: true, 
                    appContainerContextValue: {
                        initializationsForClient: {
                            currentOrganization: undefined,
                            currentOrganizationDropdownValue: undefined,
                            /* TODO RM35832: temp fix until integrate the foundation fix, can use undefined after integration and new version of foundation */
                            currentUser: null,
                            currentPermissions: undefined,
                            currentRole: undefined,
                            allOrganizationsAccess: undefined,
                            ldapAvailable: false,
                            version: DUMMY_STRING,
                            visualStyleSettings: {},
                            homePageSettings: { usePersonalHomePage: false, homePageDashboards: [{ id: 0 }], footerBarText: "" },
                            colorSettings: { data: [] },
                            internationalizationSettings: { showBothOriginalAndTranslated: false, translatableByUserLanguagesMap: {}, defaultLanguage: "en" },
                            fileBrowserBulkDownloadMaximumFileSize: 0,
                            mapSettings: { airport: DUMMY_STRING, markers: [] },
                            flightSettings: { rotationFlightOffset: 0, findByStringFlightDateOffsetHours: 0 },
                            tempSettingsXops: { tempUnitSettings: [], tempBaggageStatusSettings: [], tempFlightConveyorEjectSettings: [], tempMissionTypeSettings: [], displayForSas: false },
                            currentHumanResource: undefined,
                            hasLongStorage: false
                        } as XopsInitializationsForClient
                    }
                });
                console.log("isMobileApp, init mobile app and go to xops-mobile!");
                XopsMobileWrapper.init();
            }, 100);
            return;
        } else {
            XopsMobileWrapper.init();
        }
        super.loadInitializationsForClient_fromConstructor();
    }

    finalizeEntityDescriptors() {
        this.addEntityDescriptors([
            organizationEntityDescriptor, userEntityDescriptor, roleEntityDescriptor, roleToUserEntityDescriptor,
            settingsEntityDescriptor,
            checklistEntityDescriptor,
            chartEntityDescriptor,
            equipmentTypeEntityDescriptor,
            equipmentResourceEntityDescriptor,
            historicalMapAnalysisEntityDescriptor,
            territoryEntityDescriptor,
            entityToTagEntityDescriptor,
            tagEntityDescriptor,
            positionEntityDescriptor,
            inventoryHistoryDataEntityDescriptor,
            auditEntityDescriptor,
            airportEntityDescriptor,
            telemetryEventMappingEntityDescriptor, telemetrySystemEntityDescriptor,
            drivingEventEntityDescriptor,
            flightScheduleEntityDescriptor,
            notificationEntityDescriptor,
            problemEntityDescriptor,
            unitEntityDescriptor,
            detectionEventEntityDescriptor,
            mobileDeviceEntityDescriptor
        ]);
        new AppEntityDescriptors().init();
        return super.finalizeEntityDescriptors();
    }

    protected getFieldsForInitializationsForClient() {
        return `currentUser { id firstName lastName username isAdmin language } flightSettings { rotationFlightOffset findByStringFlightDateOffsetHours }
                mapSettings { azureMapsAPIKey bingAPIKey airport markers { markerIdField markerType showTextUnderIcon colors { name area field showInFilterBar useDurationBetweenNowAndFieldValue intervals { label from to color } } bigPopupFields { name fields } smallPopupFields { name fields } rowFields { name fields } } }
                tempSettingsXops { displayForSas tempUnitSettings { organizationId missionTypesToDisplay { id } defaultTripTimeToParking defaultLCOffset defaultMCOffset defaultSCOffset } tempFlightConveyorEjectSettings { conveyorEject color parkings { id } } tempBaggageStatusSettings { name label color displayMode } tempMissionTypeSettings { missionType startAddresses { id } setObjectEndAddressFromFlightConnectionParking missionsAwaitingForDriversPageRendererMode } }
                currentHumanResource { id identifier firstName lastName pdaIdentifier unit { id name telephone } mobileDeviceId mobileDevice { id identifier } vehicle { id identifier initBusParking { name } } currentQualifications { qualificationType { id name } startDate endDate }  }
                hasLongStorage
                `
            + super.getFieldsForInitializationsForClient();
    }

    getDisplayForSas(): boolean {
        const { tempSettingsXops } = (this.helperAppContainer.dispatchers.getState().appContainerContextValue.initializationsForClient as XopsInitializationsForClient);
        return tempSettingsXops.displayForSas;
    }

    protected resetMenuEntries() {
        super.resetMenuEntries();
        Messages.getInstance().setLanguage("en");
    }

    getUIEntityDescriptors(): { [entityName: string]: EntityDescriptor } {
        let firstTime = false;
        if (this.uiEntityDescriptors === undefined) {
            firstTime = true;
        }
        super.getUIEntityDescriptors();

        if (firstTime && this.uiEntityDescriptors) {
            delete this.uiEntityDescriptors["Leave"];
            delete this.uiEntityDescriptors["LeaveType"];
            delete this.uiEntityDescriptors["Employee"];
            delete this.uiEntityDescriptors["Department"];
            delete this.uiEntityDescriptors["Job"];
        }
        return this.uiEntityDescriptors!;
    }

    createMenuEntries(data: FoundationInitializationsForClient, entityCrudMenus: any): any {
        const frequentyUsedMenu: any[] = [];

        const fmsMenu = [
            { content: _msg("MapRealTime"), to: equipmentResourceEntityDescriptor.getEntityTableUrl() + "/realTimeMap", icon: "map outline", permission: Utils.pipeJoin([ENT_TABLE, equipmentResourceEntityDescriptor.name]) },
            equipmentResourceEntityDescriptor.getMenuEntry(),
            humanResourceEntityDescriptor.getMenuEntry(),
            drivingEventEntityDescriptor.getMenuEntry(),
            detectionEventEntityDescriptor.getMenuEntry(),
            positionEntityDescriptor.getMenuEntry(),
            historicalMapAnalysisEntityDescriptor.getMenuEntry()
        ];

        let omsMenu = [
            xopsMobileWrapperPageUrlPageMenuEntry(),
            flightEntityDescriptor.getMenuEntry(),
            taskEntityDescriptor.getMenuEntry(),
            baggageEntityDescriptor.getMenuEntry(),
            missionEntityDescriptor.getMenuEntry(),
            missionsAwaitingForDriversPageMenuEntry(),
            equipmentResourceEntityDescriptor.getMenuEntry(),
            humanResourceEntityDescriptor.getMenuEntry(),
            entityDescriptors["HumanResourceSchedule"].getMenuEntry(),
            mobileDeviceEntityDescriptor.getMenuEntry()
        ];
        if (XopsMobileWrapper.isDeviceMobileApp()) {
            omsMenu.push(xopsMobileWrapperSettingsMenuEntry());            
        }
        
        const referencedDataMenu = [
            { 
                content: _msg("Menu.referenceData.common"), icon: "file alternate", submenus: [
                    organizationEntityDescriptor.getMenuEntry(),
                    addressEntityDescriptor.getMenuEntry(),
                    territoryEntityDescriptor.getMenuEntry(),
                    airportEntityDescriptor.getMenuEntry(),
                    tagEntityDescriptor.getMenuEntry(),
                    entityToTagEntityDescriptor.getMenuEntry(),
                    checklistEntityDescriptor.getMenuEntry()
                ]
            },
            { 
                content: _msg("Menu.FMS"), icon: "file alternate", submenus: [
                    entityDescriptors["EquipmentModel"].getMenuEntry(),
                    entityDescriptors["EquipmentModelFamily"].getMenuEntry(),
                    entityDescriptors["EquipmentProductType"].getMenuEntry(),
                    entityDescriptors["EquipmentUsageLog"].getMenuEntry(),
                    telemetrySystemEntityDescriptor.getMenuEntry(),
                    telemetryEventMappingEntityDescriptor.getMenuEntry(),
                    entityDescriptors["DiagnosticTroubleCode"].getMenuEntry(),
                    emissionRatingEntityDescriptor.getMenuEntry(),
                    entityDescriptors["Puck"].getMenuEntry()
                ]
            },
            { 
                content: _msg("Menu.OMS"), icon: "file alternate", submenus: [
                    equipmentTypeEntityDescriptor.getMenuEntry(),
                    entityDescriptors["PlaneType"].getMenuEntry(),
                    unitEntityDescriptor.getMenuEntry(),
                    flightScheduleEntityDescriptor.getMenuEntry(),
                    entityDescriptors["HumanResourceShift"].getMenuEntry(),
                    entityDescriptors["HumanResourceLog"].getMenuEntry(),
                    entityDescriptors["HumanResourceMessage"].getMenuEntry()
                ]
            }
        ];

        const administrationMenu = [
            userEntityDescriptor.getMenuEntry(),
            roleEntityDescriptor.getMenuEntry(),
            roleToUserEntityDescriptor.getMenuEntry(),
            auditEntityDescriptor.getMenuEntry(),
            settingsEntityDescriptor.getMenuEntry(),
            fileBrowserPageMenuEntry()
        ];
        const reportMenu: any[] = [];

        const visualisationMenu = [
            chartEntityDescriptor.getMenuEntry(),
            dashboardEntityDescriptor.getMenuEntry(),
        ]

        if (AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_TABLE, dashboardEntityDescriptor.name]), false, data.currentPermissions)) {
            this.helperAppContainer.dispatchers.loadMenuEntries(dashboardEntityDescriptor.name).then(success => {
                this.helperAppContainer.dispatchers.getState().menuEntries[dashboardEntityDescriptor.name]?.forEach((d: any) => {
                    visualisationMenu.push({ content: d.name, to: dashboardEntityDescriptor.getEntityEditorUrl(d.id) + '/dashboard', icon: d.icon, exact: true, color: d.color });
                });
        
            });
        }
        if (AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_TABLE, chartEntityDescriptor.name]), false, data.currentPermissions)) {
            this.helperAppContainer.dispatchers.loadMenuEntries(chartEntityDescriptor.name).then(success => {
                this.helperAppContainer.dispatchers.getState().menuEntries[chartEntityDescriptor.name]?.forEach((d: any) => {
                    reportMenu.push({ content: d.name, to: chartEntityDescriptor.getEntityEditorUrl(d.id) + '/chart', icon: d.icon, exact: true, color: d.color });
                });
            });
        }

        const advancedMenu = [
            {
                content: _msg("Menu.advanced.configuration"), icon: "setting", submenus: [
                    blocklyScriptEntityDescriptor.getMenuEntry(),
                    scheduledTaskEntityDescriptor.getMenuEntry(),
                ]
            },
            { content: _msg("Menu.visualisation"), icon: "eye", submenus: visualisationMenu }
        ];

        if (data.currentUser?.isAdmin) {
            advancedMenu.push({
                content: _msg("Menu.developers"), icon: "computer", submenus: [
                    {
                        content: <Checkbox color="white" checked={localStorage.getItem(MAP_CLUSTER_MODE_KEY) === CLUSTER_MODE.PRUNE_CLUSTER} label={_msg("MapRealTime.prunecluster")}
                            onClick={() => {
                                let newValue = localStorage.getItem(MAP_CLUSTER_MODE_KEY) === CLUSTER_MODE.PRUNE_CLUSTER ? CLUSTER_MODE.CLASSIC_CLUSTER : CLUSTER_MODE.PRUNE_CLUSTER;
                                localStorage.setItem(MAP_CLUSTER_MODE_KEY, newValue);
                                window.location.reload();
                            }} />, to: "/"
                    },
                    {
                        content: <Checkbox color="white" checked={localStorage.getItem(PHONE_MODE_KEY) === "true"} label="Phone mode"
                            onClick={() => {
                                let newValue = localStorage.getItem(PHONE_MODE_KEY) === "true" ? "false" : "true";
                                localStorage.setItem(PHONE_MODE_KEY, newValue);
                                window.location.reload();
                            }} />, to: "/"
                    },
                    { content: infoScanBaggageFormPage.sliceName, to: "/" + infoScanBaggageFormPage.sliceName, icon: "suitcase" },
                    { content: infoTemplateConnectedComponent.sliceName, to: "/" + infoTemplateConnectedComponent.sliceName, icon: "pencil" },
                    inventoryHistoryDataEntityDescriptor.getMenuEntry(),                    
                    {
                        as: "div", children: <>
                            {_msg("Menu.old")} &nbsp;
                            <NavLink to="/HumanResourceForm">HR form</NavLink> | &nbsp;
                        </>
                    },
                    assignTasksToResourcePageMenuEntry(),
                    missionEventValidationPageMenuEntry(),
                    ganttAssignmentFromFilesOnDiskPageMenuEntry(),
                    zeroTrainingIndexPageMenuEntry(),
                    bluetoothDevicesPageMenuEntry(),
                    realTimeGanttPageMenuEntry()
                ],
            });
        }

        const menuEntries = [];
        // if AppMeta will add Home as intial menu entry, "Frequently used" will have itemId = 1, so 2 and 3 are reserved for FMS and OMS
        if (this.hasHomePageMenuEntry()) {
            this.menuInitialExpandedIds = { "2": true, "3": true };
        } else {
            this.menuInitialExpandedIds = { "1": true, "2": true };
        }
        menuEntries.push({ content: _msg("Menu.frequentlyUsed"), icon: "file alternate", submenus: frequentyUsedMenu });
        menuEntries.push({ content: _msg("Menu.FMS"), icon: "file alternate", submenus: fmsMenu });
        menuEntries.push({ content: _msg("Menu.OMS"), icon: "file alternate", submenus: omsMenu });
        menuEntries.push({ content: _msg("Menu.referenceData"), icon: "file alternate", submenus: referencedDataMenu });
        menuEntries.push({ content: _msg("Menu.administration"), icon: "settings", submenus: administrationMenu });
        menuEntries.push({ content: _msg("Menu.report"), icon: "file alternate", submenus: reportMenu });
        menuEntries.push({ content: _msg("Menu.advanced"), icon: "computer", submenus: advancedMenu });
        menuEntries.push({ content: _msg("Menu.support"), icon: "help circle", submenus: [
            // { content: <a href={mailto}>{_msg("Menu.ticket")}</a>, as: "div", to: ".", icon: "file alternate outline" }
        ] });

        return menuEntries;
    }

    enableModalRoutes() {
        return true;
    }

    protected getFieldsForOrg() {
        return super.getFieldsForOrg() + " airport { id }"
    }

    getOrganizationTreeIcon(itemId: string, object: any, currentOrg: Optional<Organization>, iconProps?: any, getDefaultOrgIconForParents?: boolean) {
        if (object.children?.length === 0) {
            if (object.airport) {
                return airportEntityDescriptor.getIcon(iconProps);
            }
        }
        return super.getOrganizationTreeIcon(itemId, object, currentOrg, iconProps, getDefaultOrgIconForParents);
    }

    protected renderAdditionalUserInfo(user: User): React.ReactNode {
        // on initializationsForClient will come as an ER object, if will be updated will come just with identifier
        const er = (this.helperAppContainer.dispatchers.getState().appContainerContextValue.initializationsForClient as XopsInitializationsForClient).currentHumanResource?.vehicle;
        if (!Utils.isNullOrEmpty(er) && !Utils.isNullOrEmpty(er.identifier)) {
            return <span className="no-wrap-no-overflow-ellipsis"><Icon name='truck' className="AppContainer_menu_icon_without_label" /> {er.identifier}</span>
        }
        super.renderAdditionalUserInfo(user);
    }

    getVersionInfo(version: string): React.ReactNode {
        const mobileVersion = XopsMobileWrapper.getMobileVersion();
        if (mobileVersion) {
            return <>{super.getVersionInfo(version)}<span>{mobileVersion}</span></>;
        }
        return super.getVersionInfo(version);
    }

    protected addCustomFieldsToEntityDescriptors() {
        super.addCustomFieldsToEntityDescriptors();
        //preparing data for dropdown - customFields from Settings
        entityDescriptors["TelemetryEventMapping"].getField("mappingTo").customize();
    }

    async afterLogin() {
        await super.afterLogin();
        w.xopsMobileWrapperAfterLogin();
    }

    async beforeLogout() {
        w.xopsMobileWrapperBeforeLogout();
        super.beforeLogout();
    }

    createLogoutParams() {
        return { ...super.createLogoutParams(), ...{ data: XopsMobileWrapper.getMobileDeviceInfo() } };
    }

    public goBack() {
        XopsMobileWrapper.isDeviceMobileApp() && (this.helperAppContainer.dispatchers.getState().appContainerContextValue.initializationsForClient as XopsInitializationsForClient).currentHumanResource &&
            (AppMetaTempGlobals.history.location.pathname === "/"
                || AppMetaTempGlobals.history.location.pathname === "/" + XOPS_MOBILE_PATH
                || AppMetaTempGlobals.history.location.pathname === "/" + XOPS_MOBILE_MISSIONS_PATH)
            ? AppMetaTempGlobals.history.push("/" + XOPS_MOBILE_PATH) // if mobile && HR login, redirect to mobile path
            : super.goBack();
    }

    public getAdditionalUserMenuEntries(): any[] {
        let entries = super.getAdditionalUserMenuEntries();
        if ((this.helperAppContainer.dispatchers.getState().appContainerContextValue.initializationsForClient as XopsInitializationsForClient).currentHumanResource) {
            entries.push({
                key: 'changeEquipmentResource', text: _msg("humanResource.changeEquipment"), icon: 'truck', onClick: () => {
                    w.XopsUtils.showChangeEr(null);
                }
            });
        }

        return entries;
    }

    public getAdditionalUserHeaderEntries(): any[] {
        let entries = super.getAdditionalUserHeaderEntries();
        if ((this.helperAppContainer.dispatchers.getState().appContainerContextValue.initializationsForClient as XopsInitializationsForClient).currentHumanResource) {
            entries.push({
                key: 'mobileDevice', text: <span>{XopsMobileWrapper.getMobileDeviceInfo().mobileDeviceIdentifier}</span>, icon: mobileDeviceEntityDescriptor.icon,
                disabled: true
            });
        }

        return entries;
    }

    protected async initRRCSingletons() {
        await XopsColorRegistry.INSTANCE.init();
        super.initRRCSingletons();
    }

    protected renderRRCSingletons() {
        return <>
            <XopsColorRegistryRRC id={RRC_SINGLETON} />
            {super.renderRRCSingletons()}
        </>;
    }
}


export const xopsHomePageWrappedComponent = class extends HomePage {

    protected backgroundImageURL() {
        if (XopsMobileWrapper.isDeviceMobileApp()) {
            return undefined;
        }
        return super.backgroundImageURL();
    }

    renderUnderJumbotron() {
        
        return (
            <>
                <p>&nbsp;</p>
                <Card.Group itemsPerRow='4' centered>
                    <Card>
                        <Card.Content>
                            <Card.Header><Icon name="map outline" />{_msg("Menu.map")}</Card.Header>
                            <Menu vertical fluid items={[
                                historicalMapAnalysisEntityDescriptor.getMenuEntry(),
                                positionEntityDescriptor.getMenuEntry(),
                                drivingEventEntityDescriptor.getMenuEntry(),
                                checklistEntityDescriptor.getMenuEntry(),
                                qualificationEntityDescriptor.getMenuEntry(),
                                detectionEventEntityDescriptor.getMenuEntry(),
                            ]}>
                            </Menu>
                        </Card.Content>
                    </Card>
                    <>
                        {AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_TABLE, dashboardEntityDescriptor.name])) ?
                            <Card>
                                <Card.Content>
                                    <Card.Header><Icon name="chart pie" />{_msg("Dashboard.label.plural")}</Card.Header>
                                    <Menu vertical fluid>
                                        {AppContainer.INSTANCE.props.menuEntries[dashboardEntityDescriptor.name]?.map((d: MenuEntry) => {
                                            return <Menu.Item key={d.id} content={d.name} icon={<Icon name={d.icon as any} style={{ color: d.color }} />} link={true} onClick={() => this.props.dispatchers.dispatch(push(dashboardEntityDescriptor.getEntityEditorUrl(d.id) + "/dashboard"))} />
                                        })}
                                        <Menu.Item content={_msg("Dashboard.menu.viewAll")} icon={<Icon name='ellipsis horizontal' color='grey' />} link={true}
                                            onClick={() => this.props.dispatchers.dispatch(push(dashboardEntityDescriptor.getEntityTableUrl()))} />
                                    </Menu>
                                </Card.Content>
                            </Card>
                            : null}
                        {AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_TABLE, chartEntityDescriptor.name])) ?
                            <Card>
                                <Card.Content>
                                    <Card.Header><Icon name="chart pie" />{_msg("Chart.label.plural")}</Card.Header>
                                    <Menu vertical fluid>
                                        {AppContainer.INSTANCE.props.menuEntries[chartEntityDescriptor.name]?.map((c: MenuEntry) => {
                                            return <Menu.Item key={c.id} content={c.name} icon={<Icon name={c.icon as any} style={{ color: c.color }} />} link={true} onClick={() => this.props.dispatchers.dispatch(push(chartEntityDescriptor.getEntityEditorUrl(c.id) + "/chart"))} />
                                        })}
                                        <Menu.Item content={_msg("Dashboard.menu.viewAll")} icon={<Icon name='ellipsis horizontal' color='grey' />} link={true}
                                            onClick={() => this.props.dispatchers.dispatch(push(chartEntityDescriptor.getEntityTableUrl()))} />
                                    </Menu>
                                </Card.Content>
                            </Card>
                            : null}
                    </>
                    <Card>
                        <Card.Content>
                            <Card.Header><Icon name="eye" />{_msg("Menu.datareferences")}</Card.Header>
                            <Menu vertical fluid items={[
                                equipmentResourceEntityDescriptor.getMenuEntry(),
                                humanResourceEntityDescriptor.getMenuEntry(),
                                organizationEntityDescriptor.getMenuEntry(),
                                territoryEntityDescriptor.getMenuEntry(),
                                entityDescriptors["EquipmentModel"].getMenuEntry(),
                                equipmentTypeEntityDescriptor.getMenuEntry(),
                                AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.pipeJoin([ENT_READ, ganttAssignmentEntityDescriptor.name])) ? <Menu.Item key="lastGanttAssignment" content={_msg("GanttAssignment.lastGanttAssignment")} link={true} permission={Utils.pipeJoin([ENT_READ, ganttAssignmentEntityDescriptor.name])}
                                    onClick={async () => {
                                        const lastGanttAssignment = await (ganttAssignmentEntityDescriptor as GanttAssignmentEntityDescriptor).getLastGanttAssignment();
                                        if (lastGanttAssignment) {
                                            return this.props.dispatchers.dispatch(push(ganttAssignmentEntityDescriptor.getEntityEditorUrl(lastGanttAssignment.id) + "/gantt"))
                                        }
                                        return this.props.dispatchers.dispatch(push(ganttAssignmentEntityDescriptor.getEntityTableUrl()))
                                    }}
                                /> : null
                            ]}>
                            </Menu>
                        </Card.Content>
                        <Card.Content>
                            <Card.Header><Icon name="help circle" />{_msg("Menu.support")}</Card.Header>
                            <Menu vertical fluid items={[
                                {
                                    key: "ticket", icon: "file alternate outline", as: "div", content:
                                        <a href={mailto}>{_msg("Menu.ticket")}</a>

                                },
                                // { as: NavLink, ...zeroTrainingIndexPageMenuEntry() as any, className: !allow ? "disabledItem" : undefined }
                            ]}>
                            </Menu>
                        </Card.Content>
                    </Card>
                </Card.Group>

                {/* <Segment compact style={{width: '80%', margin: 'auto', marginTop: '10px', padding: '0'}}>
                <Header as='h3' attached='top'><Icon name='dashboard' />{_msg("Dashboard.label.plural")}</Header>
                <Segment secondary attached='bottom'>     
                    <Grid centered stretched columns='4'><Grid.Row>
                        {this.props.dashboardLinks.map((d: any, index: number) => {
                            return <Grid.Column width='4'><Card link onClick={() => this.props.dispatchers.dispatch(push('/DashboardEditor/' + d.id + '/dashboard'))}>
                                <Card.Header className={'dashboardLink' + index} style={{padding: '10px', textAlign: 'center'}}>
                                    <div><style>{`
                                        .${'dashboardLink' + index} i.inverted.bordered.icon, i.inverted.circular.icon {
                                            background-color: ${d.color} !important;
                                        }
                                        `}
                                        </style>
                                            <Icon size='huge' circular bordered inverted name={d.icon} />
                                            <Header as='h3' style={{ color: d.color }}>{d.name}</Header></div>
                                    </Card.Header>
                                </Card></Grid.Column>
                            })}
                            <Grid.Column width='4'><Card link onClick={() => this.props.dispatchers.dispatch(push('/DashboardTable'))}>
                                <Card.Header style={{ padding: '10px', textAlign: 'center' }}>
                                    <Icon size='huge' style={{ margin: '0 auto' }} circular bordered color='grey' name='ellipsis horizontal' />
                                    <Header as='h3' color='grey'>{_msg("Dashboard.menu.viewAll")}</Header>
                                </Card.Header>
                            </Card></Grid.Column>
                        })}
                        <Grid.Column width='4'><Card link onClick={() => this.props.dispatchers.dispatch(push('/DashboardTable'))}>
                        <Card.Header style={{padding: '10px', textAlign: 'center'}}>
                            <Icon size='huge' style={{margin: '0 auto'}} circular bordered color='grey' name='ellipsis horizontal'/>
                            <Header as='h3' color='grey'>{_msg("Dashboard.menu.viewAll")}</Header>
                        </Card.Header>
                        </Card></Grid.Column>
                    </Grid.Row></Grid>
                </Segment></Segment> */}
            </>);
    }

};

infoLoginPage.wrappedComponentClass = XopsLoginPage;
infoHomePage.wrappedComponentClass = xopsHomePageWrappedComponent;

export function createMeta(): XopsAppMeta {
    const appMeta = new XopsAppMeta([]);
    appMeta.addConnectedPageInfos([infoPasswordResetInitPage, infoPasswordResetFinishPage,
        infoTemplateConnectedComponent, infoScanBaggageFormPage,
    ]);
    appMeta.addConnectedPageInfos([infoLoginPage]);
    appMeta.addConnectedPageInfos([infoHomePage, infoPersonalHomePage]);

    // appMeta.zeroTrainingArticlesRegistry.addFromModule(chartHistogramCountInTerritoriesZt);
    // appMeta.zeroTrainingArticlesRegistry.addFromModule(chartPieDistanceTimeInTerritoriesZt);
    // appMeta.zeroTrainingArticlesRegistry.addFromModule(chartBarsDistanceTimeInTerritoriesZt);
    // appMeta.zeroTrainingArticlesRegistry.addFromModule(chartPieCountByCriteriaZt);
    // TODO CC: temporary commented; we need to fix map stories before!
    // appMeta.zeroTrainingArticlesRegistry.addFromModule(historicalMapEquipment);
    // appMeta.zeroTrainingArticlesRegistry.addFromModule(historicalMapAnalysis);
    // appMeta.zeroTrainingArticlesRegistry.addFromModule(customQueryZt);
    appMeta.zeroTrainingArticlesRegistry.addFromModule(dashboardZt);
    appMeta.zeroTrainingArticlesRegistry.addFromModule(roleZt);

    return appMeta;
}

export const appMeta: XopsAppMeta = createMeta();

export default appMeta.getAppComponent();

// It was added here because dashboard widget editor doesn't see it
export const REAL_TIME_MAP_WIDGET = "realTimeMapWidget";
export const MAP_WIDGET = "mapWidget";
export const VEHICLE_INFORMATION_WIDGET = "vehicleInformationWidget";
export const DIAGNOSTIC_TROUBLE_CODE_WIDGET = "diagnosticTroubleCodeWidget";

DashboardWidgetFactories.INSTANCE.widgets[REAL_TIME_MAP_WIDGET] = new class extends DashboardWidgetFactory {
    allowMultiple = false;
    public componentClass = RealTimeMapWidget;

    getEntityDescriptor(widgetConfig: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor("MapRealTime")
            .addFieldDescriptor({ name: "filter", type: FieldType.filter, mode: EntityFilterMode.OBJECT, optional: true })
            .addFieldDescriptor({ name: "airport", type: "Airport", optional: true })
    }
    renderEditor(id: string, widgetConfig: any, dashboardTabRef: RefObject<DashboardTab>) {
        return this.renderEditorInternal(id, widgetConfig, dashboardTabRef, { ...widgetConfig, ...{ id: id } });
    }
    renderWidget(id: string, widgetProps: WidgetProps, dashboardTabRef: RefObject<DashboardTab>) {
        return super.renderWidget(id, {
            ...widgetProps,
            rootFilter: (widgetProps.widgetConfig as any).filter,
            airport: (widgetProps.widgetConfig as any).airport,
            entityTypes: [equipmentResourceEntityDescriptor.name],
            mapId: id,
            showGoToTableButton: true,
            layers: { [equipmentResourceEntityDescriptor.name]: { layerType: MARKER_TYPE } }
        } as any, dashboardTabRef);
    }
    getWizardInfo() {
        return { title: _msg("MapRealTime"), description: _msg("MapRealTime.content"), image: 'images/realtime-map.png' }
    }
}();

DashboardWidgetFactories.INSTANCE.widgets[MAP_WIDGET] = new class extends DashboardWidgetFactory {
    type = DashboardWidgetType.ENTITY;
    allowMultiple = false;
    componentClass = MapWidgetRRC;
    getEntityDescriptor(widgetConfig: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor("Map");
    }
    renderEditor(id: string, widgetConfig: any, dashboardTabRef: RefObject<DashboardTab>) {
        return this.renderEditorInternal(id, widgetConfig, dashboardTabRef, { ...widgetConfig, ...{ id: id } });
    }
    renderWidget(id: string, widgetProps: WidgetProps, dashboardTabRef: RefObject<DashboardTab>) {
        return super.renderWidget(id, { ...widgetProps, 
            entityTypes: [equipmentResourceEntityDescriptor.name], 
            layer: { [equipmentResourceEntityDescriptor.name]: { layerType: MARKER_TYPE } } } as any,
            dashboardTabRef);
    }
    getWizardInfo() {
        return { title: _msg("Map"), description: _msg("MapWidget.content"), image: 'images/realtime-map.png' }
    }
}();

DashboardWidgetFactories.INSTANCE.widgets[VEHICLE_INFORMATION_WIDGET] = new class extends DashboardWidgetFactory {
    type = DashboardWidgetType.ENTITY;
    componentClass = VehicleInformationWidget;
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor("FieldWidget")
            .addFieldDescriptor({ name: "title", type: FieldType.string, optional: true })
            .addFieldDescriptor({ name: "entityName", type: FieldType.entityName, initialValue: dashboardEntity.entityName, enabled: false })
    }
    getWizardInfo() {
        return { title: 'Vehicle Information Widget', description: 'Displays a information section common for multiple types of equipments.', component: VehicleInformationWidget, testState: { entity: { odometer: 2412.543, batteryLevel: "78" } } }
    };
}

DashboardWidgetFactories.INSTANCE.widgets[DIAGNOSTIC_TROUBLE_CODE_WIDGET] = new class extends DashboardWidgetFactory {
    type = DashboardWidgetType.ENTITY;
    entityType = "EquipmentResource";
    componentClass = DiagnosticTroubleCodeWidgetRRC;
    getEntityDescriptor(widgetConfig: any, dashboardEntity: any): EntityDescriptor {
        return DashboardWidgetEntityDescriptor(DIAGNOSTIC_TROUBLE_CODE_WIDGET).addFieldDescriptor({ name: "title", type: FieldType.string, optional: true });
    }
    getWizardInfo() {
        return { title: 'Diagnostic Trouble Code (DTC) Widget', description: 'Displays DTC (errors) for the equipment.', component: DiagnosticTroubleCodeWidgetRRC, testState: { id: "DiagnosticTroubleCodeWidget", status: WidgetStatus.DONE, entities: [{ name: "Handbrake Fault", code: "62" }, { name: "SRO", code: "57" }], totalCount: 2 } };
    };
};

HistoryGraphItemFactory.INSTANCE.addItemForEntityType(equipmentResourceEntityDescriptor, HistoryGraphMapItemRRC as any);

searchOptions["EquipmentResource"] = ["identifier", "plateNumber"];
searchOptions["Address"] = ["name"];
searchOptions["Territory"] = ["name"];

export function isFlexMode() {
    return window.localStorage.getItem("flexMode") === "true";
}
    
export function isIframe() {
    return window !== window.parent;
}