import gql from "graphql-tag";
import { apolloClientHolder } from "@crispico/foundation-react";
import { Utils } from "@crispico/foundation-react";
import React from "react";
import { Container, Segment, List, Image, Dimmer, Loader, Label, Icon } from "semantic-ui-react";
import { MessageExt } from "@crispico/foundation-react/components/semanticUiReactExt";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { MapSettings } from "app";
import { MapContainerLeafletRRC, MapContainerLeaflet, MarkerData, MARKER_TYPE } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { LiveVideo } from "components/LiveVideo/LiveVideo";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { NavLink } from "react-router-dom";
import { PeriodPickerRRC } from "@crispico/foundation-react/components/periodPicker/PeriodPicker";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { VIDEO_ALLOW_VIEW_BLURRED, VIDEO_ALLOW_VIEW_ORIGINAL } from "app";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { drivingEventEntityDescriptor } from "pages/DrivingEvent/drivingEventEntityDescriptor";
import { AppContainerContext } from "@crispico/foundation-react/AppContainerContext";
import { XopsAppContainerContextValue } from "XopsAppContainerContext";

var moment = require("moment");

const VIDEO_TYPE = "video";

export const FILES_PATH = Utils.adjustUrlToServerContext("videos");

export type Video = {
    date: Date,
    thumbnailPath: string,
    videoPath: string
    eventType: string,
    cameraChannel: string,
    entityId: string,
    longitude: number | undefined,
    latitude: number | undefined,
    duration: number,
}

type Props = RRCProps<VideoPageState, VideoPageReducers<VideoPageState>> & {
    equipmentResourceId: any;
};

class VideoPageState extends State {
    videos: { [key: string]: Video[] } = {};
    loading: boolean = true;
    selectedDrivingEventId: string | undefined = undefined;
    blurredThumbnails: boolean = false;
}

class VideoPageReducers<S extends VideoPageState = VideoPageState> extends Reducers<S> {
}

class VideoPage extends React.Component<Props> {
    static contextType = AppContainerContext;
    context!: XopsAppContainerContextValue;

    private mapContainerRef = React.createRef<MapContainerLeaflet>();

    selectVideoOnMap(selectedKey: string, mapContainer: MapContainerLeaflet) {
        const selected: Video = this.props.s.videos[selectedKey][0];
        if (selected.latitude && selected.longitude) {
            mapContainer.props.r.setInReduxState({ selectedLayer: { id: selected.entityId, type: VIDEO_TYPE, additionalInfo: { flyToLayer: true } } });
        }
        this.props.r.setInReduxState({ selectedDrivingEventId: selected.entityId })
    }

    async getMedia(equipmentResourceId: any, startDate: any, endDate: any, mapContainer: MapContainerLeaflet) {
        mapContainer.clearMap();
        const drivingEventDetails: Video[] = await getMedia(equipmentResourceId, startDate, endDate);

        if (!drivingEventDetails || drivingEventDetails.length === 0) {
            this.props.r.setInReduxState({ loading: false, videos: {}, selectedDrivingEventId: undefined })
            return;
        }

        const groupedDrivingEventsDetails: { [key: string]: Video[] } = {};
        drivingEventDetails.forEach((element: Video) => {
            element.thumbnailPath += "?blurred=" + this.props.s.blurredThumbnails;
            groupedDrivingEventsDetails[element.entityId] = drivingEventDetails.filter(v => v.entityId === element.entityId);
        });
        this.props.r.setInReduxState({ loading: false, videos: groupedDrivingEventsDetails, selectedDrivingEventId: undefined })

        const data: MarkerData[] = [];
        Object.entries(groupedDrivingEventsDetails).forEach(([_, videos]) => {
            const video = videos[0];
            if (!Utils.isNullOrEmpty(video.longitude) && !Utils.isNullOrEmpty(video.latitude)) {
                data.push({
                    id: video.entityId,
                    point: { longitude: video.longitude!, latitude: video.latitude! },
                    text: (entityDescriptors["EquipmentResource"].getField(video.eventType).getLabel() || video.eventType) + " " + moment(video.date).format(Utils.dateTimeWithSecFormat)
                });
            }
        });
        mapContainer.addOrUpdateLayers(data, VIDEO_TYPE);
    }

    componentDidMount(): void {
        this.props.r.setInReduxState({ blurredThumbnails: !AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_ORIGINAL) })
    }

    componentWillUnmount() {
        this.props.r.setInReduxState({ videos: {}, selectedDrivingEventId: undefined })
    }

    onSelectedLayerChanged() {
        const id = this.mapContainerRef.current!.props.s.selectedLayer?.id;
        if (id !== undefined) {
            document.getElementById(id.toString())?.scrollIntoView();
            this.props.r.setInReduxState({ selectedDrivingEventId: id.toString() })
        }
    }

    renderMarkerIcon(): React.ReactNode {
        return <span className='fa fa-stack fa-lg'><i className={'fa-solid fa-video-camera fa-stack-1x'} ></i></span>
    }

    renderTooltipContent(markerData: MarkerData): React.ReactElement {
        return <div className="flex-container">{markerData.text}</div>;
    }

    render() {
        return (
            <>
                <div className="flex-container flex-center">
                    <Segment basic className="flex-container-row flex-center flex-grow-shrink-no-overflow less-padding no-margin" style={{ width: '100%' }}>
                        <MessageExt className="flex-grow">
                            <PeriodPickerRRC id="periodPicker"
                                onChange={async (startDate, endDate) => { await this.getMedia(this.props.equipmentResourceId, startDate, endDate, this.mapContainerRef.current!); }} />
                            <span className="float-right"><LiveVideo entityId={this.props.equipmentResourceId} /></span>
                        </MessageExt>
                    </Segment>
                </div>
                <SplitPaneExt minSize={250} size="35%">
                    <Container className="wh80">
                        <Dimmer active={this.props.s.loading}>
                            <Loader size="big">{_msg("general.loading")}</Loader>
                        </Dimmer>
                        <List className="flex-grow-shrink-no-overflow less-padding no-margin" divided relaxed>
                            {this.props.s.videos && Object.entries(this.props.s.videos).sort((a, b) => a[1][0].date < b[1][0].date ? 1 : -1).map(([key, videos]) => {
                                const videosCopy = [...videos].sort((a, b) => a.cameraChannel.localeCompare(b.cameraChannel));
                                const video = videosCopy[0];
                                return (
                                    <List.Item id={video.entityId} onClick={() => this.selectVideoOnMap(key, this.mapContainerRef.current!)} style={{ background: this.props.s.selectedDrivingEventId === video.entityId ? "lightgrey" : undefined }}>
                                        <VideoElement video={video} />
                                    </List.Item>
                                );
                            })}
                        </List>
                    </Container>
                    <div className="no-padding-margin flex-container flex-grow-shrink-no-overflow MapRealTime_topParent">
                        <MapContainerLeafletRRC id="mapContainerLeafletVideoTab" ref={this.mapContainerRef} pruneClusterMode={false} saveCenterZoomInStorage={true}
                            renderTooltipContent={this.renderTooltipContent} renderMarkerIcon={this.renderMarkerIcon}
                            azureMapsAPIKey={this.context.initializationsForClient.mapSettings.azureMapsAPIKey}
                            bingAPIKey={this.context.initializationsForClient.mapSettings.bingAPIKey}
                            mapId="map-videos" layers={{ [VIDEO_TYPE]: { layerType: MARKER_TYPE } }} onSelectedLayerChanged={() => this.onSelectedLayerChanged()} />
                    </div>
                </SplitPaneExt>
            </>
        );
    }
}

export const VideoPageRRC = ReduxReusableComponents.connectRRC(VideoPageState, VideoPageReducers, VideoPage);

class VideoElement extends React.Component<{ video: Video }> {
    render() {
        const video = this.props.video;
        return (
            <>
                <div className="flex-center gap3">
                    {(AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_ORIGINAL) || AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED)) && <Image src={FILES_PATH + video.thumbnailPath} size="small" rounded />}
                    <List.Content>
                        <List.Header>{_msg("general.date")}: {moment(video.date).format(Utils.dateTimeWithSecFormat)}</List.Header>
                        <List.Description>{_msg("VideoPage.eventType")}: {entityDescriptors["EquipmentResource"].getField(video.eventType).getLabel() || video.eventType}</List.Description>
                        <List.Description>{_msg("VideoPage.location")}: {!Utils.isNullOrEmpty(video.longitude) && !Utils.isNullOrEmpty(video.latitude) ? video.longitude + " / " + video.latitude : ""}</List.Description>
                        <List.Description>{_msg("general.duration")}: {moment.duration(video.duration, "seconds").format()} </List.Description>
                        <List.Description>
                            <NavLink to={entityDescriptors["DrivingEvent"].getEntityEditorUrl(video.entityId)}>
                                <Label icon size="small">
                                    {_msg("VideoPage.showEvent")}
                                    <Icon name="share" />
                                </Label>
                            </NavLink>
                        </List.Description>
                    </List.Content>
                    <div className="VideoPage_play_button small-margin-right">{drivingEventEntityDescriptor.getField("video").renderField({ id: video.entityId, drivingEventId: video.entityId })}</div>
                </div>
            </>
        )
    }
}

async function getMedia(equipmentResourceId: Number, startDate: String, endDate: String) {
    const getMediaQuery = gql(`query getMedia($equipmentResourceId: Long!, $startDate: Date, $endDate: Date){
        smartwitnessService_media(
            equipmentResourceId: $equipmentResourceId
            startDate: $startDate
            endDate: $endDate
        ) {date, thumbnailPath, videoPath, eventType, cameraChannel, entityId, latitude, longitude, duration}
      }`);
    const result: Video[] = await (await apolloClientHolder.apolloClient.query({ query: getMediaQuery, variables: { equipmentResourceId, startDate, endDate } })).data.smartwitnessService_media;
    return result;
}