import React from "react";
import { RealTimeMapProps, RealTimeMapRRC } from "./realTimeMap/RealTimeMap";
import { AbstractWidget, AbstractWidgetProps } from "@crispico/foundation-react/pages/dashboard/AbstractWidget";

export type RealTimeMapWidgetProps = AbstractWidgetProps & RealTimeMapProps;

/*
 * in past the RealTimeMap was used directly as widget,
 * now exist this class which render the RealTimeMap(with own refresh) 
 * and notify Dashboard about refresh(not RealTimeMap refresh, a empty refresh if is required)
 */

export class RealTimeMapWidget extends AbstractWidget<RealTimeMapWidgetProps> {

    render() {
        return <RealTimeMapRRC {...this.props} />;
    }
}
