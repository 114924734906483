import gql from "graphql-tag";

export const SEND_POSITION = gql`
    mutation sendPosition($decodedPosition: DecodedPositionInput) {
        mobileDeviceService_sendPosition(decodedPosition: $decodedPosition)
    }
`;

export const GET_BLUETOOTH_DEVICES_PAGE_SETTINGS = gql`
    query getBluetoothDevicesPageSettings {
        mobileDeviceService_bluetoothDevicesPageSettings {
            prefixes plateNumberMaximumLength replicatedSequence minRssi
        }
    }
`;