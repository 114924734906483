import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, EntityDescriptor, EntityTablePage, EntityTableSimple, EntityTableSimpleReducers, EntityTableSimpleState, ENT_ADD, ENT_DELETE, ENT_SAVE, Optional, Organization, Utils, EntityTablePageState, EntityTablePageReducers, EntityTablePagePartialProps } from "@crispico/foundation-react";
import { AppContainerContext } from "@crispico/foundation-react/AppContainerContext";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { AddressForMap } from "apollo-gen/AddressForMap";
import { loadAddressesForMap, loadAddressesForMapVariables } from "apollo-gen/loadAddressesForMap";
import { MarkerSettings } from "app";
import { addressEntityDescriptor } from "AppEntityDescriptors";
import { EditMode, ID, MapContainerLeafletRRC, MapContainerLeaflet, MarkerData, MARKER_TYPE, SelectedLayer } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { MapGoToButton, RealTimeUtils } from "components/realTimeMap/RealTimeUtils";
import Interweave from "interweave";
import lodash from "lodash";
import { DELETE_ADDRESS_FROM_MAP, LOAD_ADDRESSES_FOR_MAP, SAVE_ADDRESSES_FOR_MAP } from "pages/Address/queries";
import React from "react";
import { Dimmer, Loader, Message } from "semantic-ui-react";
import { XopsAppContainerContextValue } from "XopsAppContainerContext";

class AddressTablePageState extends EntityTablePageState {
    addresses: Optional<{ [key: string]: AddressForMap }> = undefined;
    openEditor: boolean = false;
}

class AddressTablePageReducers<S extends AddressTablePageState = AddressTablePageState> extends EntityTablePageReducers<S> {
    add(newAddresses: AddressForMap[]) {
        if (!this.s.addresses) {
            this.s.addresses = {};
        }
        newAddresses.forEach(newAddress => {
            this.s.addresses![newAddress.id] = newAddress;
        });
    }

    remove(ids: ID[]) {
        ids.forEach(id => {
            delete this.s.addresses![id];
        });
    }
}

type AddressTablePageProps = EntityTablePagePartialProps & RRCProps<AddressTablePageState, AddressTablePageReducers>;

class AddressTablePage extends EntityTablePage<AddressTablePageProps> {

    static contextType = AppContainerContext;
    context!: XopsAppContainerContextValue;

    async loadAddressesOnMap(ids?: Optional<ID[]>) {
        let filterFromCQ = this.getFilterForLoad();
        let filter: Filter;
        if (ids && ids.length > 0) {
            const filters: Filter[] = [];
            filters.push(filterFromCQ);
            filters.push(Filter.create("id", FilterOperators.forNumber.in, ids.join(", ")));
            filter = Filter.createComposed(FilterOperators.forComposedFilter.and, filters);
        } else {
            filter = filterFromCQ;
        }
        const list: Optional<AddressForMap[]> = (await apolloClient.query<loadAddressesForMap, loadAddressesForMapVariables>({
            query: LOAD_ADDRESSES_FOR_MAP,
            variables: FindByFilterParams.create().filter(filter)
        })).data.addressService_findByFilter?.results;

        if (!list || list.length === 0) {
            this.props.r.add([]);
            return;
        }

        this.props.r.add(list);
        this.addAddressesOnMap(list);
    }

    addAddressesOnMap(list: AddressForMap[]) {
        let data: MarkerData[] = [];
        list.forEach((a: AddressForMap) => {
            if (a.longitude !== undefined && a.latitude !== undefined &&
                a.longitude !== null && a.latitude !== null) {
                data.push({ id: a.id, point: { longitude: a.longitude, latitude: a.latitude }, text: a.name || "" });
            }
        });
        this.mapContainerRef.current?.addOrUpdateLayers(data, this.props.entityDescriptor.name);
    }

    isAllowed(editMode: EditMode): boolean {
        const mode = editMode === EditMode.DRAW ? ENT_ADD : editMode === EditMode.EDIT ? ENT_SAVE : ENT_DELETE;
        const permission = Utils.pipeJoin([mode, this.props.entityDescriptor.name]);
        if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission)) {
            return false;
        }
        return true;
    }

    async updateAddresses(markers: MarkerData[], org: Optional<Organization>) {
        let ids: number[] = [];
        for (const m of markers) {
            let address = m.id ? { ...this.props.s.addresses![m.id] } : { name: "UNKNOWN" } as AddressForMap;

            let fieldsAndValue: any = {
                name: address.name,
                latitude: m.point.latitude,
                longitude: m.point.longitude
            };
            if (org) {
                fieldsAndValue['organization'] = { id: org?.id }
            }
            const id = (await apolloClient.mutate({ mutation: SAVE_ADDRESSES_FOR_MAP, variables: { params: { id: address.id, fieldsAndValues: fieldsAndValue } } })).data.addressService_save?.id;
            ids.push(id);
        }
        // refresh the table
        this.refresh();

        // update only modified & added features
        await this.loadAddressesOnMap(ids);

        if (markers.length === 1 && markers[0].id === undefined) {
            this.mapContainerRef.current?.props.r.setInReduxState({ selectedLayer: { id: ids[0], type: this.props.entityDescriptor.name } });
        }
    }

    async removeAddresses(markers: MarkerData[]) {
        const permission = Utils.pipeJoin([ENT_DELETE, this.props.entityDescriptor.name]);
        if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission, true)) {
            return;
        }

        let ids: ID[] = [];
        for (const m of markers) {
            ids.push(m.id!);
            await apolloClient.mutate({ mutation: DELETE_ADDRESS_FROM_MAP, variables: { id: m.id } });
        }
        // refresh the table
        this.refresh();

        this.props.r.remove(ids);
        this.mapContainerRef.current?.removeLayers(this.props.entityDescriptor.name, ids);
    }

    mapContainerRef = React.createRef<MapContainerLeaflet>();

    constructor(props: AddressTablePageProps) {
        super(props);
        this.renderTooltipContent = this.renderTooltipContent.bind(this);
        this.renderMarkerIcon = this.renderMarkerIcon.bind(this);

        const that = this;
        this.tableSimpleClass = ReduxReusableComponents.connectRRC(EntityTableSimpleState, EntityTableSimpleReducers, class extends EntityTableSimple {
            onSelectItem(selectId: any) {
                that.mapContainerRef.current!.props.r.setInReduxState({ selectedLayer: { id: selectId, type: this.props.entityDescriptor.name } });
            }
        });
    }

    componentDidUpdateInternal(prevProps?: AddressTablePageProps) {
        super.componentDidUpdateInternal(prevProps);

        // set this here instead of componentDidMount, because ref is null there
        if (!this.mapContainerRef?.current?.props.s.editDrawEnabledOnType) {
            this.mapContainerRef?.current?.props.r.setInReduxState({ editDrawEnabledOnType: this.props.entityDescriptor.name, editMode: undefined });
        }

        // verify if [0, 0] -> default value; if not [0, 0], then it means the center was set by value from session storage, so we don't want to reset it
        if (this.mapContainerRef.current && lodash.isEqual(this.mapContainerRef.current!.props.s.center, [0, 0]) && this.context.initializationsForClient.mapSettings.airport !== null) {
            RealTimeUtils.setAirportCoordinates(this.context.initializationsForClient.mapSettings.airport, this.mapContainerRef);
        }

        if (this.props.s.openEditor != prevProps?.s.openEditor) {
            const selectedId = this.mapContainerRef.current?.props.s.selectedLayer?.id;
            if (selectedId) {
                this.goToEditor(addressEntityDescriptor.getEntityEditorUrl(selectedId));
            }
            this.props.r.setInReduxState({ openEditor: false });
        }
    }

    renderTooltipContent(markerData: MarkerData, type: string, additionalInfo?: { pointId?: ID }): React.ReactElement {
        return <div className="flex-container">{markerData.text}</div>;
    }

    renderMarkerIcon(markerData: MarkerData, type: string): React.ReactNode {
        const markerSettings: Optional<MarkerSettings> = this.context.initializationsForClient.mapSettings.markers.find(m => m.markerType === this.props.entityDescriptor.name);
        return <><span className='fa fa-stack fa-lg'><i className={'fa fa-map-marker fa-stack-1x'} style={{ color: 'blue' }}></i></span>
            {markerSettings?.showTextUnderIcon ? <div style={{ font: 'bold 12px Lato', whiteSpace: "nowrap" }}>{markerData.text}</div> : undefined}</>;
    }

    protected onCustomQueryFilterChanged() {
        super.onCustomQueryBarChanged();
        this.props.r.setInReduxState({ addresses: undefined });
        this.entityTableSimpleRef.current?.setSelected(undefined);
        this.mapContainerRef.current?.clearMap();
        this.loadAddressesOnMap();
    }

    protected preRenderButtons(params: any): Array<OverrideableElement> {
        return [
            ...super.preRenderButtons(params),
            {
                element:
                    <div key="selectAirport" className="MapContainerHeader_segment">
                        <Dimmer inverted active={this.props.s.addresses === undefined}></Dimmer>
                        <MapGoToButton options={RealTimeUtils.getMapGoToButtonProps(this.mapContainerRef)} />
                    </div>
            }
        ];
    };

    renderMain() {
        return (<>
            <Utils.Observer value={this.context.initializationsForClient.currentOrganization} didUpdate={() => {
                RealTimeUtils.selectAirportOnCurrentOrganizationToFilterByChange(this.mapContainerRef, this.context.initializationsForClient.currentOrganization);
            }} />
            <SplitPaneExt size="30%">
                {this.mapContainerRef.current?.props.s.editMode
                    ? <Message data-cy="editModeMessage" className="flex-container flex-grow">
                        <Interweave content={_msg(this.props.entityDescriptor.name + "." + this.mapContainerRef.current!.props.s.editMode + "Mode")} />
                    </Message>
                    : super.renderMain()}
                <>
                    <Dimmer inverted active={this.props.s.addresses === undefined}><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>

                    <MapContainerLeafletRRC id={"mapContainerLeafletAddressTable"} ref={this.mapContainerRef} mapId={"address-map"}
                        pruneClusterMode={localStorage.getItem('clusterMode') === 'pruneCluster'}
                        renderTooltipContent={this.renderTooltipContent} renderMarkerIcon={this.renderMarkerIcon} onEditModeChanged={() => this.forceUpdate()}
                        azureMapsAPIKey={this.context.initializationsForClient.mapSettings.azureMapsAPIKey}
                        bingAPIKey={this.context.initializationsForClient.mapSettings.bingAPIKey} saveCenterZoomInStorage={true}
                        isAllowed={(editMode: EditMode) => { return this.isAllowed(editMode) }}
                        onLayerAdded={(marker: MarkerData, type: string) => this.updateAddresses([marker], this.context.initializationsForClient.currentOrganization || undefined)}
                        onLayersEdited={(markers: MarkerData[], type: string) => this.updateAddresses(markers, this.context.initializationsForClient.currentOrganization || undefined)}
                        onLayersRemoved={(markers: MarkerData[], type: string) => this.removeAddresses(markers)}
                        onDoubleClickLayer={(prevValue: Optional<SelectedLayer>) => this.props.r.setInReduxState({ openEditor: true })}
                        layers={{ [this.props.entityDescriptor.name]: { layerType: MARKER_TYPE, options: { useCluster: false, flyToSelectedMarker: true } } }} />
                </>
            </SplitPaneExt>
        </>)
    }
}

const AddressTablePageRRC = ReduxReusableComponents.connectRRC(AddressTablePageState, AddressTablePageReducers, AddressTablePage);

export class AddressEntityDescriptor extends EntityDescriptor {

    renderTable() {
        return <AddressTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
    }

}
