import { RRCProps, ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { GanttUtils } from "../gantt/GanttUtils";
import { AbstractGantt, AbstractGanttProps, AbstractGanttReducers, AbstractGanttState, GanttData } from "../gantt/AbstractGantt";
import { HighlightedInterval } from "@crispico/react-timeline-10000";
import _ from "lodash";
import { Checkbox, Segment } from "semantic-ui-react";
import { ColumnDefinition, EntityDescriptor, apolloClientHolder } from "@crispico/foundation-react";
import { missionEntityDescriptor, missionEvent2EntityDescriptor, taskEntityDescriptor } from "AppEntityDescriptors";
import moment from "moment";
import { PARENT_ELEMENT } from "@crispico/react-timeline-10000/types/timeline";
import React from "react";
import { MissionItemRenderer } from "pages/gantt/typeRenderers/MissionItemRenderer";
import { ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import gql from "graphql-tag";

const SNAP = 10;

class MissionGanttComponentState extends AbstractGanttState {
    displayItemOnSeparateRowIfOverlap: boolean = false;
    showPlannedTime: boolean = false;
    tableWidth: number = 300;
}

class MissionGanttComponentReducers<S extends MissionGanttComponentState = MissionGanttComponentState> extends AbstractGanttReducers<S> { }

// lot of props from abstractGantt isn't used(e.g. entitiesVersion, hideResources), but we need to provide it beacause the component extend the AbstractGantt
export type MissionGanttComponentProps = {
    dateOffset?: number
} & AbstractGanttProps;

export class MissionGanttComponent extends AbstractGantt<MissionGanttComponentProps, MissionGanttComponentReducers, MissionGanttComponentState> {

    static defaultProps = {
        // default 10min
        dateOffset: 600000
    }

    get missionId() {
        if (!this.props.entities) {
            return 0;
        }
        const keys = Object.keys(this.props.entities["Mission2"]);
        return keys.length ? Number(keys[0]) : 0;
    }

    async componentDidMount() {
        await this.updateStartEndDates();
    }

    protected async componentDidUpdateInternal(prevProps?: RRCProps<MissionGanttComponentState, MissionGanttComponentReducers> & MissionGanttComponentProps) {
        if (prevProps && (prevProps.dateOffset != this.props.dateOffset || this.missionId != this.missionId || !_.isEqual(prevProps.entities, this.props.entities))) {
            this.updateStartEndDates();
        }
        if (prevProps && (prevProps.s.showPlannedTime != this.props.s.showPlannedTime || !_.isEqual(prevProps.entities, this.props.entities))) {
            this.processData(this.props.entities);
        }
    }

    protected async updateStartEndDates() {
        const mission = GanttUtils.findByUid(GanttUtils.toEntityUid("Mission2", this.missionId), this.props.entities);
        const start = new Date(mission.startTime || this.props.s.start).getTime() - this.props.dateOffset!;
        const end = new Date(mission.endTime || this.props.s.end).getTime() + this.props.dateOffset!;
        await this.props.r.setInReduxState({ start, end });
        this.processData(this.props.entities);
    }

    protected processData(newEntities: any) {
        let mission: any;
        if (!newEntities || Object.keys(newEntities).length == 0 || !(mission = GanttUtils.findByUid(GanttUtils.toEntityUid("Mission2", this.missionId), newEntities))) {
            return;
        }

        const data: GanttData = { layers: [], items: [], groups: [] };
        let groupIndex = -1;
        mission.tasks.forEach((task: any) => {
            groupIndex++;
            data.groups.push({
                key: GanttUtils.toEntityUid("Task", task.id),
                entityUid: GanttUtils.toEntityUid("Task", task.id),
                id: groupIndex,
                visible: true
            });
            const start = new Date(task.actualStartTime).getTime();
            const end = new Date(task.actualEndTime).getTime();

            data.items.push({
                key: GanttUtils.toEntityUid("Task", task.id),
                entityUid: GanttUtils.toEntityUid("Task", task.id),
                row: groupIndex,
                start,
                end,
                ganttId: this.constructor.name,
                visible: true
            });
            this.props.s.showPlannedTime && data.layers.push({
                rowNumber: groupIndex,
                start,
                end,
                style: { backgroundColor: "red", opacity: '0.2' },
                visible: true
            });
            mission.events.filter((e: any) => new Date(e.creationdate).getTime() >= start && new Date(e.creationdate).getTime() <= end)
                .sort((x: any, y: any) => {
                    return new Date(x.creationdate).getTime() - new Date(y.creationdate).getTime();
                }).forEach((event: any) => {
                    data.items.push({
                        key: GanttUtils.toEntityUid("MissionEvent2", event.id),
                        entityUid: GanttUtils.toEntityUid("MissionEvent2", event.id),
                        row: groupIndex,
                        start: new Date(event.creationdate).getTime(),
                        end: this.getEventItemEnd(new Date(event.creationdate).getTime()),
                        ganttId: this.constructor.name,
                        visible: true
                    })
                })
        })

        this.props.r.setInReduxState({ data });
    }

    private getEventItemEnd(start: number) {
        const x = this.getPixelAtTime(moment(start)) + PARENT_ELEMENT(this.timelineId).getBoundingClientRect().left;
        return this.getTimeAtPixel(x + 10).valueOf();
    }

    protected getSnap() {
        return SNAP;
    }

    protected getTableColumns(): ColumnDefinition[] | undefined {
        const columns = super.getTableColumns();
        if (columns) {
            return columns;
        }
        const fields = ["name", "startTime", "endTime"];
        let cc: ColumnDefinition[] = [];
        fields.forEach(f => cc.push({ width: 100, name: f }));
        return cc;
    }

    protected getEntityDescriptor(): EntityDescriptor {
        return taskEntityDescriptor;
    }

    protected getContextProps() {
        return {
            ... super.getContextProps(),
            useDnD: false
        };
    }

    protected getHighlightedIntervals() {
        const mission = GanttUtils.findByUid(GanttUtils.toEntityUid("Mission2", this.missionId), this.props.entities);
        if (!mission) {
            return [];
        }
        const highlightedIntervals = [];
        highlightedIntervals.push(<HighlightedInterval start={new Date(mission.startTime).getTime()} end={new Date(mission.endTime).getTime()}
            style={{ backgroundColor: MissionItemRenderer.getMissionColor(mission, 0.2) }} />);

        if (this.props.s.showPlannedTime && mission.initialStartTime && mission.initialEndTime) {
            highlightedIntervals.push(<HighlightedInterval
                start={new Date(mission.initialStartTime).getTime()}
                end={new Date(mission.initialEndTime).getTime()}
                style={{ backgroundColor: "red", opacity: '0.3' }} />
            );
        }
        return highlightedIntervals;
    }

    protected getTimelineProps() {
        return {
            ...super.getTimelineProps(),
            droppableProps: { dragable: false },
            displayItemOnSeparateRowIfOverlap: this.props.s.displayItemOnSeparateRowIfOverlap,
            onItemHover: this.onItemHover,
            onSplitChange: (tableWidth: number) => {
                this.processData(this.props.entities);
                this.props.r.setInReduxState({ tableWidth });
            },
        };
    }

    protected renderTopBar(): React.ReactNode {
        return <div className="flex-container-row flex-center flex-wrap small-padding gap10">
            <div className="flex-container-row gap10">
                {_msg("MissionGanttComponent.displayItemOnSeparateRowIfOverlap.label")}
                <Checkbox style={{ paddingTop: 3 }} checked={this.props.s.displayItemOnSeparateRowIfOverlap} onClick={(_, data) => {
                    this.props.r.setInReduxState({ displayItemOnSeparateRowIfOverlap: data.checked ? true : false });
                }
                } />
            </div>
            <div className="flex-container-row flex-justify-content-center gap10">
                {_msg("MissionGanttComponent.showPlannedTime.label")}
                <Checkbox style={{ paddingTop: 3 }} checked={this.props.s.showPlannedTime} onClick={(_, data) =>
                    this.props.r.setInReduxState({ showPlannedTime: data.checked ? true : false })
                } />
            </div>
        </div>;
    }

    render() {
        return <Segment className="flex-container flex-grow gap10" style={{ minHeight: '300px' }}>
            {super.render()}
        </Segment>
    }
}

export const MissionGanttComponentRRC = ReduxReusableComponents.connectRRC(MissionGanttComponentState, MissionGanttComponentReducers, MissionGanttComponent);

export class MissionDetailsComponent extends React.Component<{ id: number, dateOffset?: number }, { entity: any }> {

    constructor(props: { id: number, dateOffset?: number }) {
        super(props);
        this.state = { entity: undefined };
    }

    async componentDidUpdate(prevProps: Readonly<{ id: number, dateOffset?: number }>) {
        if (prevProps && !_.isEqual(prevProps.id, this.props.id)) {
            await this.loadEntity();
        }
    }

    async componentDidMount() {
        await this.loadEntity();
    }

    async loadEntity() {
        const name: string = `${_.lowerFirst(missionEntityDescriptor.name)}Service_findById`;
        const fieldsToRequest = missionEntityDescriptor.getGraphQlFieldsToRequest() + " events { " + missionEvent2EntityDescriptor.getGraphQlFieldsToRequest() + " } \
        tasks { " + taskEntityDescriptor.getGraphQlFieldsToRequest(["id", "name", "taskGroup", "actualStartTime", "actualEndTime", "startTime", "endTime", "taskType", "missionType", "comment", "quantity", "offloadStatus", "startAddress", "endAddress", "requiredEquipmentResourceQualificationType", "equipmentResourceFillPercentage"]) + " mission { id status } } ";
        const query = gql(`query q($id: Long) { 
            ${name}(id: $id) {
                ${ID} ${fieldsToRequest}
            }
        }`);
        const entity = (await apolloClientHolder.apolloClient.query({ query: query, variables: { id: this.props.id } })).data[name];
        this.setState({ entity });
    }

    render() {
        const { entity } = this.state;
        if (!entity) {
            return null;
        }
        // need to create the entities because the item render use GanttUtils.findByid, and it look in gantt props entities
        let entities: { [entityName: string]: { [id: number]: any } } = {};
        entities["Mission2"] = {};
        entities["Mission2"][entity.id] = entity;
        entities["Task"] = {};
        entity.tasks?.forEach((task: any) => {
            entities["Task"][task.id] = task;
        })
        entities["MissionEvent2"] = {};
        entity.events?.forEach((event: any) => {
            entities["MissionEvent2"][event.id] = event;
        })
        return <MissionGanttComponentRRC id="MissionDetails_MissionGanttComponent" entities={entities} dateOffset={this.props.dateOffset} />
    }
}
