import { apolloClient, ConnectedComponentInSimpleComponent, ConnectedPageInfo, FilterBar, sliceFilterBar } from "@crispico/foundation-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { ModalContent, ModalActions, Button } from "semantic-ui-react";
import { APPLY_FOA_FOR_FLIGHTS } from "./queries";
import React from "react";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";

type FlightAndObjectActionApplyModalProps = {
    showModal: boolean,
    flightFilter: Filter,
    foaFilter: Filter | null,
    onClose: () => void,
    setFlightFilter: (flightFilter: Filter) => void
}

type FlightAndObjectActionApplyModalState = {
    confirmationPhase: boolean
}

const DEFAULT_FILTER_APPLY: Filter = Filter.createComposedForClient(FilterOperators.forComposedFilter.and, [Filter.createForClient("date", FilterOperators.forDate.today, undefined)]);

export class FlightAndObjectActionApplyModal extends React.Component<FlightAndObjectActionApplyModalProps, FlightAndObjectActionApplyModalState> {

    constructor(props: FlightAndObjectActionApplyModalProps) {
        super(props);
        this.state = {
            confirmationPhase: false
        }
    }

    render() {
        return <ModalExt open={this.props.showModal} size="small" onClose={() => {
                this.props.onClose();
                this.setState({ confirmationPhase: false });
            }}
            header={_msg("FlightAndObjectAction.apply.modalHeader.label")}
            content={<ModalContent className="FlightAndObjectAction_applyModalContent">
                {this.state.confirmationPhase ? _msg("FlightAndObjectAction.apply.confirmation.label") :
                    <ConnectedComponentInSimpleComponent info={new ConnectedPageInfo(sliceFilterBar, FilterBar, "applyFilterBar", {
                        setFilterInCustomQuery: (filter: Filter) => this.props.setFlightFilter(filter),
                        entityName: "Flight"
                    })} rootFilter={this.props.flightFilter} />}
            </ModalContent>}
            actions={<ModalActions>
                <Button primary content={_msg(this.state.confirmationPhase ? "FlightAndObjectAction.apply.label" : "FlightAndObjectAction.apply.next.label")} onClick={async () => {
                    if (this.state.confirmationPhase) {
                        await apolloClient.mutate({
                            mutation: APPLY_FOA_FOR_FLIGHTS,
                            variables: { flightFilter: Filter.eliminateDisabledFilters(this.props.flightFilter), foaFilter: this.props.foaFilter },
                        });

                        this.props.setFlightFilter(DEFAULT_FILTER_APPLY);
                        this.props.onClose();
                        this.setState({ confirmationPhase: false });
                        return;
                    }
                    this.setState({ confirmationPhase: true });
                }} />
                <Button content={_msg("general.cancel")} onClick={async () => {
                    this.props.onClose();
                    this.setState({ confirmationPhase: false });
                }} />
            </ModalActions>}
        />
    }

}