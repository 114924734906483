import { Optional } from "@crispico/foundation-react";
import { ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { MarkerSettings } from "app";
import { MapContainerLeafletRRC, MapContainerLeaflet, MarkerData, MARKER_TYPE } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { DEFAULT_ZOOM_LEVEL } from "components/MapContainerLeaflet/MapLayerHelpers";
import { CLUSTER_MODE, MAP_CLUSTER_MODE_KEY } from "components/realTimeMap/RealTimeMap";
import { uniqueId } from "lodash";
import { equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import { EquipmentResourceUtils } from "pages/EquipmentResource/EquipmentResourceUtils";
import React from "react";
import { WidgetConfig, WidgetProps } from "@crispico/foundation-react/pages/dashboard/dashboardTab/WidgetWrapper";
import { AppContainerContext } from "@crispico/foundation-react/AppContainerContext";
import { XopsAppContainerContextValue } from "XopsAppContainerContext";
import { AbstractWidget, AbstractWidgetProps } from "@crispico/foundation-react/pages/dashboard/AbstractWidget";

export class MapWidgetState extends State { }

export class MapWidgetReducers extends Reducers<MapWidgetState> { }

export type Props = RRCProps<MapWidgetState, MapWidgetReducers> & WidgetProps<WidgetConfig & AbstractWidgetProps>;
export class MapWidget extends AbstractWidget<Props> {

    static contextType = AppContainerContext;
    context!: XopsAppContainerContextValue;

    private mapContainerRef = React.createRef<MapContainerLeaflet>();

    constructor(props: Props) {
        super(props);
        this.renderMarkerIcon = this.renderMarkerIcon.bind(this);
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: Props) {
        const { props } = this;
        if (!prevProps || prevProps.entityForAttachedDashboard?.[ID] !== props.entityForAttachedDashboard?.[ID]) {
            const eq = props.entityForAttachedDashboard;
            this.mapContainerRef.current?.clearMap();
            if (eq && eq.identifier && eq.lastPointLongitude && eq.lastPointLatitude) {
                this.mapContainerRef.current?.props.r.setInReduxState({ zoom: DEFAULT_ZOOM_LEVEL, center: [eq.lastPointLatitude, eq.lastPointLongitude] });

                const markerSettings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(this.context.initializationsForClient.mapSettings);
                let marker: MarkerData = EquipmentResourceUtils.getMarkerFromEquipmentResource(eq, markerSettings);
                this.mapContainerRef.current?.addOrUpdateLayers([marker], equipmentResourceEntityDescriptor.name);
            } else {
                this.mapContainerRef.current?.props.r.setInReduxState({ zoom: DEFAULT_ZOOM_LEVEL, center: [0, 0] });
            }
        }
    }

    renderMarkerIcon(markerData: MarkerData, type: string): React.ReactNode {
        const markerSettings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(this.context.initializationsForClient.mapSettings);
        return EquipmentResourceUtils.renderEquipmentResourceIcon(this.props.entityForAttachedDashboard, markerData, markerSettings);
    }

    render() {
        return <div className="no-padding-margin flex-container flex-grow-shrink-no-overflow MapRealTime_topParent" >
            <MapContainerLeafletRRC id={uniqueId("mapContainerLeaflet-")} ref={this.mapContainerRef}
                pruneClusterMode={localStorage.getItem(MAP_CLUSTER_MODE_KEY) === CLUSTER_MODE.PRUNE_CLUSTER}
                renderMarkerIcon={this.renderMarkerIcon}
                azureMapsAPIKey={this.context.initializationsForClient.mapSettings.azureMapsAPIKey}
                bingAPIKey={this.context.initializationsForClient.mapSettings.bingAPIKey} enableGestureHandling
                mapId={this.props.dashboardEntity ? String(this.props.dashboardEntity.id) : "map-widget"}
                layers={{ [equipmentResourceEntityDescriptor.name]: { layerType: MARKER_TYPE, options: { hideTooltipOnHoveredLayer: true, flyToSelectedMarker: false } } }} />
        </div>
    }
}

export const MapWidgetRRC = ReduxReusableComponents.connectRRC(MapWidgetState, MapWidgetReducers, MapWidget);
