import { apolloClientHolder, Utils } from "@crispico/foundation-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { PrivateRoute } from "@crispico/foundation-react/reduxHelpers/ConnectedPageHelper";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { XopsMobileWrapper } from "pages/XopsMobile/XopsMobileWrapper";
import React from "react";
import { Button, ButtonGroup, Header, Icon, Input, Modal, Segment } from "semantic-ui-react";
import { WebBluetoothHelper, MobileBluetoothHelper, AbstractBluetoothHelper } from "./BluetoothUtils";
import { Props, RenderItemParams, Tree, TreeReducers, TreeState } from "@crispico/foundation-react/components/TreeRRC/Tree";
import moment from "moment";
import { GET_BLUETOOTH_DEVICES_PAGE_SETTINGS } from "./queries";
import { getBluetoothDevicesPageSettings_mobileDeviceService_bluetoothDevicesPageSettings } from "apollo-gen/getBluetoothDevicesPageSettings";

const MIN_SCAN_INTERVAL = 1;
const DEFAULT_SCAN_INTERVAL = 10;

export interface BluetoothDevice {
    name: string,
    id: string,
    rssi: number,
    advertisements: number,
    lastAdvertisement?: string[],
    timestamp: number,
    isPuck?: boolean
}

export type BluetoothDevicesPageSettings = getBluetoothDevicesPageSettings_mobileDeviceService_bluetoothDevicesPageSettings;

type BluetoothDevicesProps = Props & RRCProps<BluetoothDevicesTreeState, BluetoothDevicesTreeReducers> & {
    displayOnlyPucks?: boolean
};

class BluetoothDevicesTreeState extends TreeState { }

class BluetoothDevicesTreeReducers<S extends BluetoothDevicesTreeState = BluetoothDevicesTreeState> extends TreeReducers<S> {
    protected _getChildren(item: any): { localId: string, item: any; }[] {
        if (Array.isArray(item)) {
            return item.map((value: any, index: any) => ({
                localId: value.id + "",
                item: value
            }));
        }
        let { id, name, ...newItem } = item;
        return [{
            localId: id + "_child",
            item: newItem,
        }]
    }

    protected _hasChildren(item: any) {
        if (Array.isArray(item) || item.id !== undefined) {
            return true
        }
        return false;
    }
}

class BluetoothDevicesTree extends Tree<BluetoothDevicesProps> {
    protected renderItem = (params: RenderItemParams) => {
        const objectId = params.linearizedItem.itemId.split(Utils.defaultIdSeparator);
        let containsDetails = false;
        let root = this.props.root;
        let result = undefined;

        if (objectId.length === 2 && objectId[1].includes("_child")) {
            containsDetails = true;
        }

        for (let i = 0; i < root.length; i++) {
            if (root[i].id === objectId[0]) {
                if (containsDetails) {
                    if (!this.props.displayOnlyPucks || (this.props.displayOnlyPucks && root[i].isPuck)) {
                        result = <div>
                            <p>{_msg("BluetoothDevice.rssi.label")} {root[i].rssi}</p>
                            <p>{_msg("BluetoothDevice.advertisements.label")} {root[i].advertisements}</p>
                            {root[i].isPuck ?
                                root[i].lastAdvertisement.map((advertisement: any) => <p>{advertisement}</p>)
                                : null}
                            <p>{_msg("BluetoothDevice.timestamp.label")} {moment(root[i].timestamp).format(Utils.dateTimeFormat)}</p>
                        </div>;
                    }
                } else {
                    if (!this.props.displayOnlyPucks || (this.props.displayOnlyPucks && root[i].isPuck)) {
                        result = <div>
                            <p>{root[i].name}</p>
                        </div>
                    }
                }
                break;
            }
        }

        return result;
    }

    // In children[1] we have result of renderItem function. If it is undefined,
    // we will not render a segment 
    protected renderItemWrapperInternal(props: any, ...children: any) {
        if (!children[1]) {
            return <></>;
        }
        return React.createElement(Segment, props, ...children);
    }
}

const BluetoothDevicesTreeRRC = ReduxReusableComponents.connectRRC(BluetoothDevicesTreeState, BluetoothDevicesTreeReducers, BluetoothDevicesTree);

export class BluetoothDevicesPageState extends State {
    devices: BluetoothDevice[] = [];
    scanStarted: boolean = false;
    openChangeScanningTimeModal: boolean | [number, number] = false;
    scanInterval: number = DEFAULT_SCAN_INTERVAL;
    errorMessage?: string = undefined;
    displayOnlyPucks?: boolean = true;
    createPositions?: boolean = true;
    settings: BluetoothDevicesPageSettings | undefined = undefined;
}

export class BluetoothDevicesPageReducers<S extends BluetoothDevicesPageState = BluetoothDevicesPageState> extends Reducers<S> {
}

type LocalState = {
    inputValueForScanInterval: number;
    filterPucks?: boolean;
    createPositions?: boolean;
}

type BluetoothDevicesPageProps = RRCProps<BluetoothDevicesPageState, BluetoothDevicesPageReducers>;

export class BluetoothDevicesPage extends React.Component<BluetoothDevicesPageProps, LocalState> {
    private scanner: AbstractBluetoothHelper;

    constructor(props: BluetoothDevicesPageProps) {
        super(props);

        this.state = {
            inputValueForScanInterval: props.s.scanInterval,
            filterPucks: props.s.displayOnlyPucks,
            createPositions: props.s.createPositions,
        };
        this.scanner = XopsMobileWrapper.isDeviceMobileApp() ? MobileBluetoothHelper : WebBluetoothHelper;
        this.openMenu = this.openMenu.bind(this);
        this.renderChangeScanningTimeModal = this.renderChangeScanningTimeModal.bind(this);
        this.addDevice = this.addDevice.bind(this);
    }

    async componentDidMount() {
        const settings = (await apolloClientHolder.apolloClient.query({ query: GET_BLUETOOTH_DEVICES_PAGE_SETTINGS })).data["mobileDeviceService_bluetoothDevicesPageSettings"];
        this.props.r.setInReduxState({ settings })
    }

    protected openMenu(e: any) {
        const rect = document.getElementById("scanDropdownButton")!.getBoundingClientRect();
        this.props.r.setInReduxState({ openChangeScanningTimeModal: [rect.left, rect.bottom] });
    }

    protected renderChangeScanningTimeModal() {
        return <ModalExt size="small" transparentDimmer open={this.props.s.openChangeScanningTimeModal} closeIcon
            className="BluetoothDevicesPage_modal" header={_msg("BluetoothDevicesPage.options")}
            content={
                <Modal.Content className="flex-container gap10">
                    <div className="flex-container">
                        <label>{this.scanner.getLabelForScanInterval()}</label>
                        <Input type="number" value={this.state.inputValueForScanInterval}
                            onChange={(event, data) => { this.setState({ inputValueForScanInterval: Number(data.value) }); }} />
                    </div>
                    {this.scanner.renderExtraOptions((value: LocalState) => this.setState(value), () => this.state)}
                </Modal.Content>
            }
            actions={[
                <Button primary onClick={() => {
                    this.props.r.setInReduxState({
                        openChangeScanningTimeModal: false,
                        createPositions: this.state.createPositions,
                        displayOnlyPucks: this.state.filterPucks,
                        scanInterval: (this.state.inputValueForScanInterval < MIN_SCAN_INTERVAL) ? MIN_SCAN_INTERVAL : this.state.inputValueForScanInterval
                    });
                }} content={_msg("general.apply")} />
            ]}
            onClose={() => {
                this.props.r.setInReduxState({
                    openChangeScanningTimeModal: false,
                });
            }} />
    }

    protected addDevice(newDevice: any) {
        let alreadyAdded = false;
        let devices: BluetoothDevice[] = [...this.props.s.devices];
        let newDeviceId = newDevice.address || (newDevice.device && newDevice.device.id);
        let deviceData = this.scanner.decodeAdvertisement(newDevice.advertisement);

        if (newDevice.rssi && this.props.s.settings && this.props.s.settings.minRssi && newDevice.rssi < this.props.s.settings.minRssi) {
            return;
        }

        devices = devices.map((device) => {
            if (device.id === newDeviceId) {
                alreadyAdded = true;
                if (deviceData.checkIsPuck && this.props.s.createPositions && device.lastAdvertisement && this.scanner.checkIfAdvertisementWasChanged &&
                    this.scanner.sendPosition && this.scanner.checkIfAdvertisementWasChanged(device.lastAdvertisement, deviceData.decodedAdvertisement)) {
                    this.scanner.sendPosition(newDevice, this.props.s.settings);
                }

                return {
                    name: device.name,
                    id: device.id,
                    rssi: newDevice.rssi,
                    advertisements: device.advertisements + 1,
                    lastAdvertisement: deviceData.decodedAdvertisement,
                    timestamp: Utils.now().getTime(),
                    isPuck: deviceData.checkIsPuck
                };
            }
            return device;
        });

        if (!alreadyAdded) {
            devices.push({
                name: newDevice.name ? newDevice.name : _msg("BluetoothDevicesPage.unknown"),
                id: newDeviceId,
                rssi: newDevice.rssi,
                advertisements: 1,
                lastAdvertisement: deviceData.decodedAdvertisement,
                timestamp: Utils.now().getTime(),
                isPuck: deviceData.checkIsPuck
            });
            if (deviceData.checkIsPuck && this.props.s.createPositions && this.scanner.sendPosition) {
                this.scanner.sendPosition(newDevice, this.props.s.settings);
            }
        }

        devices.sort((a, b) => b.rssi - a.rssi);

        this.props.r.setInReduxState({ devices: devices })
    }

    render() {
        return <>
            <div className="BluetoothDevicesPage_page flex-container flex-grow">
                <Header className="BluetoothDevicesPage_header" as="h3">
                    <Icon name="tablet alternate" size="mini" />
                    {_msg("BluetoothDevicesPage.title")}
                </Header>
                <Segment className="BluetoothDevicesPage_buttons">
                    <ButtonGroup>
                        <Button onClick={() => this.scanner.scan(this.addDevice, this.props.r.setInReduxState, this.props.s.scanInterval)} className="BluetoothDevicesPage_scanButton">
                            <Icon name="rss" />{_msg("BluetoothDevicesPage.scan")}
                        </Button>
                        <Button id="scanDropdownButton" className="BluetoothDevicesPage_scanDropdownButton" icon="setting"
                            onClick={(e) => { this.openMenu(e); }} />
                    </ButtonGroup>
                    {this.renderChangeScanningTimeModal()}
                </Segment>
                <Segment className="BluetoothDevicesPage_devices flex flex-grow">
                    {this.props.s.scanStarted ?
                        <div className="BluetoothDevicesPage_wait flex">
                            {this.props.s.errorMessage ? this.props.s.errorMessage : _msg("BluetoothDevicesPage.waitMessage")}
                        </div> :
                        <BluetoothDevicesTreeRRC id="BluetoothDevicesTreeRRC"
                            root={this.props.s.devices} displayOnlyPucks={this.props.s.displayOnlyPucks} />
                    }
                </Segment>
            </div>
        </>;
    }
}

export const BluetoothDevicesPageRRC = ReduxReusableComponents.connectRRC(BluetoothDevicesPageState, BluetoothDevicesPageReducers, BluetoothDevicesPage);

export const bluetoothDevicesPageUrl = "/BluetoothDevices";
export const bluetoothDevicesPageRoute = () =>
    <PrivateRoute key="bluetoothDevicesPage"
        path={bluetoothDevicesPageUrl}
        render={(props) => <BluetoothDevicesPageRRC {...props} id="bluetoothDevicesPage" />} />

export const bluetoothDevicesPageMenuEntry = () => {
    return {
        id: "bluetoothDevicesPage",
        content: _msg("BluetoothDevicesPage.title"),
        to: bluetoothDevicesPageUrl, exact: true, icon: "tablet alternate",
    }
};
