import React from "react";
import { Segment, Header } from "semantic-ui-react";
import { ResponsivePieExt, helper, PieDatum } from "@crispico/foundation-react/components/nivoExt";
import { ChartConfig, ChartTab, ChartTabProps, ChartTabReducers, ChartTabState, getChartGenerationPeriodFromConfig} from "pages/Chart/ChartTab";
import moment from "moment";
import Measure from "react-measure";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

export class PieDistanceTimeInTerritoriesTabState extends ChartTabState {
    distanceTimeTerritories = [] as Array<{territory: any, timeSpent: number, distanceCovered: number, equipmentResources: Array<{equipmentResource: any, timeSpent: number, distanceCovered: number}>}>;
    timeData = [] as PieDatum[];
    distanceData = [] as PieDatum[];
    tooltipData = {} as any;
}

export class PieDistanceTimeInTerritoriesTabReducers<S extends PieDistanceTimeInTerritoriesTabState = PieDistanceTimeInTerritoriesTabState> extends ChartTabReducers<S> {
    prepareData(p: { savedData: string, config: ChartConfig }) {
        let result = JSON.parse(p.savedData);
        if (result) {
            this.s.distanceTimeTerritories = result;
        }

        const distanceData: PieDatum[] = [];
        const timeData: PieDatum[] = [];
        const tooltipData: any = {};
        this.s.distanceTimeTerritories.forEach((t: {territory: any, timeSpent: number, distanceCovered: number, equipmentResources: Array<{equipmentResource: any, timeSpent: number, distanceCovered: number}>}) => {
            timeData.push({id: t.territory.id, label: t.territory.name, value: Number.parseFloat((t.timeSpent / 60).toPrecision(2))});
            distanceData.push({id: t.territory.id, label: t.territory.name, value: Number.parseFloat(t.distanceCovered.toPrecision(2))});
            tooltipData[t.territory.id] = [];
            t.equipmentResources.forEach((e: any) => {
                tooltipData[t.territory.id].push({name: e.equipmentResource.identifier + ' (' + e.equipmentResource.equipmentType?.name + ' )', min: e.timeSpent, km: Number.parseFloat(e.distanceCovered.toPrecision(2))});
            });
        })
        this.s.timeData = timeData;
        this.s.distanceData = distanceData;
        this.s.tooltipData = tooltipData;
    }
}

type Props = RRCProps<PieDistanceTimeInTerritoriesTabState, PieDistanceTimeInTerritoriesTabReducers> & ChartTabProps;
type LocalState = { height: number }

export class PieDistanceTimeInTerritoriesTab extends ChartTab<Props, LocalState> {
    constructor(props: Props) {
        super(props);
        this.state = { height: 0 };
    }

    render() {
        const props = this.props;
        const { startDate, endDate } = getChartGenerationPeriodFromConfig(props.config);
        return <div className="flex-container flex-grow-shrink-no-overflow less-padding gap5">
            {this.renderTopBar(moment(startDate), moment(endDate))}
            <Measure bounds onResize={(contentRect) => this.setState({ height: contentRect.bounds?.height ? contentRect.bounds?.height : 0 }) }>
                {({ measureRef }) => {
                    return <div ref={measureRef} style={{ width: "calc(100vw - 1em)", height: "100%",  marginTop: "0.25em" }}>
                        <SplitPaneExt defaultSize="50%" style={{ height: this.state.height }}>
                            <Segment className="wh100 overflow-hidden" data-cy="Chart.pieDistanceTimeInTerritories.distanceChart">
                                <Header textAlign='center'>{_msg('Chart.distanceTime.distance.title')}</Header>
                                <Pie data={props.s.distanceData} tooltipData={props.s.tooltipData} unit={'km'} secondaryUnit={'km'} />
                            </Segment>
                            <Segment className="wh100 overflow-hidden" data-cy="Chart.pieDistanceTimeInTerritories.timeChart">
                                <Header textAlign='center'>{_msg('Chart.distanceTime.time.title')}</Header>
                                <Pie data={props.s.timeData} tooltipData={props.s.tooltipData} unit={'h'} secondaryUnit={'min'} />
                            </Segment>
                        </SplitPaneExt>
                    </div>
                }}
            </Measure>
        </div>
    }
}

export const Pie = (props: {data: PieDatum[], tooltipData: any, unit: string, secondaryUnit: string}) => {
    return (<ResponsivePieExt data={props.data} margin={{ top: 0, right: 60, bottom: 20, left: 60 }}
        arcLabel={d => `${d.label}`} arcLinkLabel={d => `${d.value}${props.unit}`} renderTooltipContent={ p => (
        <Segment className="DistanceAndTimeInTerritories_tooltip">
            <div style={{backgroundColor: p.data.color, width: '12px', height: '12px', display: 'inline-block'}}></div> {p.data.label}: <strong>{p.data.value}{props.unit}</strong>
            <p>{props.tooltipData[p.data.id] && props.tooltipData[p.data.id].length > 0 ? helper(Array.from(props.tooltipData[p.data.id]).map((t: any) => t.name + ' - ' + t[props.secondaryUnit] + props.secondaryUnit + ', ' )) : ''}</p>
        </Segment>
    )} />);
}

export const PieDistanceTimeInTerritoriesTabRRC = ReduxReusableComponents.connectRRC(PieDistanceTimeInTerritoriesTabState, PieDistanceTimeInTerritoriesTabReducers, PieDistanceTimeInTerritoriesTab);
