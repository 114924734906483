import { apolloClient, DummyToRememberPeopleToCast, FieldDescriptor } from "@crispico/foundation-react";
import React from "react";
import { LOAD_REGISTRATIONS_PARAMETER, SAVE_REGISTRATIONS_PARAMETER } from "./queries";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { Form, Button, ModalContent } from "semantic-ui-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { FormikProps } from "formik";

const SIGNIFICANT_REGISTRATIONS = "SIGNIFICANT_REGISTRATIONS"

interface Parameter {
    id?: number | null
    name?: string,
    value?: string
}

interface RegistrationsEditorState {
    openEditModal: boolean,
    registrationsParameter?: Parameter
}

export class RegistrationsFieldDescriptor extends FieldDescriptor {
    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return <div className="FlightAndObjectAction_registrationsFieldEditor">
            {super.renderFieldEditor(formikProps, additionalFieldEditorProps)}
            <RegistrationsEditor />
        </div>;
    }
}

class RegistrationsEditor extends React.Component<{}, RegistrationsEditorState> {

    constructor(props: {}) {
        super(props);

        this.state = { openEditModal: false, registrationsParameter: undefined };
    }

    async componentDidMount() {
        let registrationsParameter: Parameter = { name: SIGNIFICANT_REGISTRATIONS, value: "", id: null }
        const results: Parameter[] = (await apolloClient.query({
            query: LOAD_REGISTRATIONS_PARAMETER,
            variables: FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [Filter.create("name", FilterOperators.forString.equals, SIGNIFICANT_REGISTRATIONS)])),
            context: { showSpinner: false }
        })).data["parameterService_findByFilter"].results;

        if (results.length > 0) {
            registrationsParameter = results[0];
        }

        this.setState({ registrationsParameter });
    }

    render() {
        return <>
            <Button onClick={(event) => { event.preventDefault(); this.setState({ openEditModal: true }) }} content={_msg("general.edit")} />
            <ModalExt
                open={this.state.openEditModal} size={"small"}
                onClose={() => this.setState({ openEditModal: false })}
                header={_msg("FlightAndObjectAction.registrationsEditorHeader.label")}
                content={<ModalContent>
                    <Form.Input className="FlightAndObjectAction_registrationsFieldEditor_input" value={this.state.registrationsParameter?.value}
                        label={_msg("FlightAndObjectAction.registrations.label")} onChange={(event, data) => this.setState({
                            registrationsParameter: {
                                ...this.state.registrationsParameter,
                                value: data.value as undefined | string
                            }
                        })} />
                </ModalContent>}
                actions={[
                    <Button primary content={_msg("general.save")} onClick={async () => {
                        let { id, ...fieldsAndValues } = this.state.registrationsParameter!;

                        let registrationsParameter = (await apolloClient.mutate({
                            mutation: SAVE_REGISTRATIONS_PARAMETER,
                            variables: { params: { id, fieldsAndValues } }
                        })).data["parameterService_save"];

                        this.setState({ registrationsParameter, openEditModal: false });
                    }} />
                ]}
            />
        </>
    }
}