import gql from "graphql-tag";

export const QUALIFICATION_TYPE_FOR_MAP = gql`
    fragment QualificationTypeForMap on QualificationType {
        id name description airline customBadge
        unit { id name }
        organization { id name qualifiedName }
        planeType { id name }
        planeTypeGroup { id name } 
    }
`;

export const LOAD_QUALIFICATION_TYPES = gql`
    query getQualificationTypes($params: FindByStringParamsInput) {
        qualificationTypeService_findByString(params: $params) {
            ...QualificationTypeForMap
        }
    }
    ${QUALIFICATION_TYPE_FOR_MAP}
`;

export const UPDATE_QUALIFICATIONS = gql`
    mutation updateQualifications($qualificationsParams: [SaveParams_LongInput]) {
        qualificationService_updateQualifications(qualificationsParams: $qualificationsParams) 
    }
`;

export const DELETE_BY_FILTER_QUALIFICATIONS = gql`
    mutation deleteQualifications($params: FindByFilterParamsInput) {
        qualificationService_deleteByFilter(params: $params) 
    }
`;

export const LOAD_HUMAN_RESOURCE = gql`
    query getHumanResource($id: Long) {
        humanResourceService_findById(id: $id) {
            id firstName lastName identifier archived qualifications {
                id qualificationType { id description name } startDate endDate
            }
        }
    }
`;