
import { apolloClientHolder, Optional } from "@crispico/foundation-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { GalleryMedia } from "components/LiveVideo/GalleryMedia";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import React from "react";
import { FILES_PATH, Video } from "pages/EquipmentResource/VideoPage";
import { Button, Icon, Modal, Divider } from "semantic-ui-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { VIDEO_ALLOW_VIEW_BLURRED, VIDEO_ALLOW_VIEW_ORIGINAL } from "app";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { LOAD_DRIVING_EVENT_FOR_VIDEO_MODAL, LOAD_VIDEOS_BY_DRIVING_EVENT } from "./queries";
import { DrivingEventForVideoModal } from "apollo-gen/DrivingEventForVideoModal";

type DrivingEventVideoButtonProps = RRCProps<DrivingEventVideoButtonState, DrivingEventVideoButtonReducers<DrivingEventVideoButtonState>> & { drivingEventId: Number };

class DrivingEventVideoButtonState extends State {
    videoModalOpen: boolean = false;
    permissionsModalOpen: boolean = false;
    videos: { [key: string]: string } = {};
    drivingEvent: Optional<DrivingEventForVideoModal> = undefined;
}

class DrivingEventVideoButtonReducers<S extends DrivingEventVideoButtonState = DrivingEventVideoButtonState> extends Reducers<S> {
}

class DrivingEventVideoButton extends React.Component<DrivingEventVideoButtonProps> {

    async loadVideosAndDrivingEvent(drivingEventId: Number, blurred: boolean) {
        const results: Video[] = (await apolloClientHolder.apolloClient.query({ query: LOAD_VIDEOS_BY_DRIVING_EVENT, variables: { drivingEventId } }))
            .data.smartwitnessService_videosByDrivingEvent;
        const loadedVideos: { [key: string]: string } = {};
        results.forEach((result) => loadedVideos[result.cameraChannel] = FILES_PATH + result.videoPath + "?blurred=" + blurred);
        const result: DrivingEventForVideoModal = (await apolloClientHolder.apolloClient.query({ query: LOAD_DRIVING_EVENT_FOR_VIDEO_MODAL, variables: { id: drivingEventId } }))
            .data.drivingEventService_findById;
        this.props.r.setInReduxState({ videos: loadedVideos, drivingEvent: result });
    }

    protected renderDrivingEventInfoInTopBar() {
        const drivingEvent = this.props.s.drivingEvent;
        return drivingEvent ? <div className="flex-justify-content-center gap20">{[
            this.renderColumn([this.renderField("DrivingEvent", "type", drivingEvent), this.renderField("EquipmentResource", "identifier", drivingEvent.equipmentResource)]),
            this.renderColumn([this.renderField("DrivingEvent", "value", drivingEvent), this.renderField("EquipmentResource", "plateNumber", drivingEvent.equipmentResource)]),
            this.renderColumn([this.renderField("DrivingEvent", "measurementUnit", drivingEvent), this.renderField("EquipmentResource", "videoRecorderId", drivingEvent.equipmentResource)]),
            this.renderColumn([this.renderField("DrivingEvent", "date", drivingEvent), this.renderField("DrivingEvent", "latitude", drivingEvent)]),
            this.renderColumn([this.renderField("DrivingEvent", "humanResource", drivingEvent), this.renderField("DrivingEvent", "longitude", drivingEvent),])
        ]}</div> : <h3 className="margin-auto">{_msg("VideoPage.noInfo")}</h3>
    }

    protected renderField(entityDescriptorName: string, field: string, entity: any): JSX.Element {
        const fd = entityDescriptors[entityDescriptorName].getField(field);
        return <div key={fd.getLabel()} className="no-margin flex-container-row gap3">
            <h4 className="no-margin">{fd.getLabel()}:</h4>
            <div className="flex-container-row gap3">{fd.renderField(entity)}</div>
        </div>
    }

    protected renderColumn(children: JSX.Element[]) {
        return <div className="no-margin flex-container gap5">
            {children}
        </div>
    }

    render() {
        return (
            <>
                <ModalExt closeIcon open={this.props.s.permissionsModalOpen} size="mini" onClose={() => this.props.r.setInReduxState({ permissionsModalOpen: false })}>
                    <Modal.Header>{_msg("VideoPage.chooseToPlay")}</Modal.Header>
                    <Modal.Content className="wh100" style={{ display: "grid", justifyItems: "center" }}>
                        <Button
                            icon="play"
                            circular
                            size="small"
                            content={_msg("VideoPage.playOriginal")}
                            color="green"
                            onClick={() => {
                                this.props.r.setInReduxState({ permissionsModalOpen: false, videoModalOpen: true })
                                this.loadVideosAndDrivingEvent(this.props.drivingEventId, false);
                            }}
                        />
                        <Divider />
                        <Button
                            icon="play"
                            circular
                            size="small"
                            content={_msg("VideoPage.playBlurred")}
                            color="blue"
                            onClick={() => {
                                this.props.r.setInReduxState({ permissionsModalOpen: false, videoModalOpen: true })
                                this.loadVideosAndDrivingEvent(this.props.drivingEventId, true);
                            }}
                        />
                    </Modal.Content>
                </ModalExt>

                <ModalExt closeIcon open={this.props.s.videoModalOpen} style={{ width: "95%", height: "95%" }} onClose={() => this.props.r.setInReduxState({ videoModalOpen: false })}>
                    <Modal.Content className="wh100">
                        <GalleryMedia data={this.props.s.videos} loading={false} video={true} progressbarVisible={true} renderInTopBar={() => this.renderDrivingEventInfoInTopBar()} />
                    </Modal.Content>
                </ModalExt>

                <Button icon circular size="small" style={{ transform: 'translate(50%, -10%)' }}
                    onClick={() => {
                        if (AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_ORIGINAL) || AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED, true)) {
                            if (AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED) && AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_ORIGINAL)) {
                                this.props.r.setInReduxState({ permissionsModalOpen: true });
                            } else {
                                this.props.r.setInReduxState({ videoModalOpen: true });
                                this.loadVideosAndDrivingEvent(this.props.drivingEventId, AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED));
                            }
                        }
                    }}
                >
                    <Icon name="play" color="blue" />
                </Button>
            </>
        );
    }
}

export const DrivingEventVideoButtonRRC = ReduxReusableComponents.connectRRC(DrivingEventVideoButtonState, DrivingEventVideoButtonReducers, DrivingEventVideoButton);